const TOOLS = [
  {
    name: 'Marketing Megaprompts',
    longDescription: "<p>Makerbox's Marketing Mega-Prompts is your ultimate marketing companion designed to turn you into a ChatGPT pro marketing machine. No more generic marketing prompts. </p><p>And while there are hundreds of free ChatGPT prompts out there, what makes Makerbox's Mega-Prompts stand out is their exceptional quality, carefully crafted for marketers, indie hackers, and solopreneurs.</p><p>Here's what you'll get:</p><p></p><p>40 marketing mega-prompts tailored to solopreneurs.</p><p>Develop killer marketing strategies, revamp your landing pages, and crank out stellar content in a flash.</p><p>Unconventional ideas that nobody else is using, smart segmentation, and 100% personalization options.</p><p></p><p>Make your marketing strategy stand out with positioning stories, marketing funnels, storytelling arches, and more. Brainstorm tweet ideas, influencer marketing ideas, subject line variations, and catchy product names with ChatGPT. These prompts are designed with the needs of solopreneurs in mind, taking the pressure off your marketing efforts and letting ChatGPT do the heavy lifting.</p>",
    category: 'Marketing',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Marketing-Mega-Prompts-ChatGPT.jpeg',
    website: 'https://makerbox.lemonsqueezy.com/?aff=xyrn4',
    email: 'contact@lemonsqueezy.com',
    metaDescription: "Makerbox's Marketing Mega-Prompts is your ultimate marketing companion designed to turn you into a ChatGPT pro marketing machine",
    description: "Makerbox's Marketing Mega-Prompts is your ultimate marketing companion designed to turn you into a ChatGPT pro marketing machine",
    slug: 'marketing-megaprompts'
  },
  {
    name: 'Sheet AI',
    longDescription: `
    <p>SheetAI put's AI 🪄 with Brain 🧠 in your Google Sheets.</p>
    <p>describe what you want in plain English, and watch the magic happen in your spreadsheet! 🪄✨</p>
    <p>SheetAI.app revolutionizes your Google Sheets experience with a powerful suite of AI-driven functions to help you automate tasks and generate insights.</p>
    <p>SHEETAI_BRAIN simplifies copywriting by storing and retrieving essential information. Quickly craft taglines and content for any app just by referencing its name, while the AI fetches relevant details from your database. Supports data addition via text and URLs.</p>
    <p>Get a variety of AI-generated answers to choose from, providing you with diverse solutions and perspectives.</p>
    
    `,
    category: 'Productivity',
    imgUrl: 'https://www.sheetai.app/assets/sheetai-open-graph-image.png',
    website: 'https://www.sheetai.app/',
    email: 'contact@sheetai.app',
    metaDescription: 'SheetAI put\'s AI 🪄 with Brain 🧠 in your Google Sheets.',
    description: 'SheetAI put\'s AI 🪄 with Brain 🧠 in your Google Sheets.',
    slug: 'sheet-ai'
  },
  {
    name: 'Mixo',
    longDescription: `
    <p>Launch a website in seconds with AI.</p>
    <p>Have an idea for a startup, product or service? Bring it to life with AI effortlessly and watch it grow.</p>
    <p>With just a brief description of your idea, Mixo uses AI to generate your website in seconds. Start collecting subscribers with stunning landing pages that require no code or design. Get started with a built-in email waiting list plus everything you need to launch, grow and test your ideas.</p>
    <p>Mixo takes the pain out of customizing your site and validating your product ideas. Connect with your customers via email, surveys or interviews. It's great for pre-launching products , gathering insights, building waiting lists, running beta testing programs or just validating new product ideas.</p>
    
    `,
    category: 'Copywriting',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/mixo-ai-website-builder.jpg',
    website: 'https://www.mixo.io/',
    email: 'contact@mixo.io',
    metaDescription: 'Launch a business in seconds with AI.',
    description: 'Launch a business in seconds with AI.',
    slug: 'mixo'
  },
  {
    name: 'User Evaluation',
    longDescription: "<p>User Evaluation improves the efficiency and depth of consumer research. For market researchers and customer-focused teams, User Evaluation is a killer tool</p><p>Discover new insights from your data, whether it's audio, video, text, or CSV files. The AI chat experience, similar to ChatGPT, allows you to brainstorm ideas based on customer conversations and retains a record of prior messages for quick reference. With rich text and collaboration tools built right in, sharing your insights has never been faster.</p><p>An AI assistant who can assist you in creating research ideas, outcomes, and even summaries of consumer interactions.</p><p></p><p>Audio, video, text, and CSV files are all supported.</p><p>AI conversations for session analysis and idea generation</p><p>AI-assisted research paper authoring and research documents</p><p>Organize insights on a Kanban board</p><p>Rich text and collaboration features allow for simple sharing.</p><p>Start your first project with free credits.</p>",
    category: 'Marketing',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/User-Evaluation-website-research-tool.jpeg',
    website: 'https://www.userevaluation.com/?ref=spiff.store',
    email: 'contact@userevaluation.com',
    metaDescription: 'User Evaluation improves the efficiency and depth of consumer research',
    description: 'User Evaluation improves the efficiency and depth of consumer research',
    slug: 'user-evaluation'
  },
  {
    name: 'Crear AI',
    longDescription: "<p>Crear.ai lets you forget about writer's block and produce content 10X faster using AI. With over 20 templates to choose from, it covers everything from Instagram captions to SEO-driven blog posts. You can personalize the AI to fit your desired tone, keywords, and output length. In a matter of seconds, you'll see the text you had in mind appear, saving you precious minutes or even hours of work.</p><p>What makes Crear.ai stand out is its range of templates, supported languages, and customization options. With the Pro plan, you can write in 25 languages, and the Magic plan even offers early access to new features. The platform offers a perfect match for anyone searching for a versatile, time-saving content generation tool. Just imagine all the cool stuff you could create with Crear.ai at your fingertips!</p>",
    category: 'Copywriting',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/crear-ai-content-writing-tool.jpeg',
    website: 'https://www.crear.ai/',
    email: 'contact@crear.ai',
    metaDescription: 'Crear.ai lets you forget about writer\'s block and produce content 10X faster using AI.',
    description: 'Crear.ai lets you forget about writer\'s block and produce content 10X faster using AI.',
    slug: 'crear-ai'
  },
  {
    name: 'Elephas',
    longDescription: "<p>AI assistant Elephas helps you write faster, from emails to blogs, to code, and social media posts. Use it on Mac, iPhone, or iPad, and choose from different writing modes to get the most professional results. Create summaries with the click of a button, generate Excel formulas from descriptions, generate entire presentations from short prompts, and blast out viral headlines all within whatever app you prefer to work in.</p><p>Elephas makes it easy to brainstorm blog ideas and develop outlines. It also makes it easy to write professional emails, create presentations, write technical blogs and code. It will ensure all your grammar is correct, so you can probably cancel a few other app subscriptions. </p><p>It's a smooth, efficient workflow that's perfect for business people, technical people, and content writers.</p>",
    category: 'Copywriting',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Elephas-personal-AI-writing-assistant-for-Mac-and-iOS.jpg',
    website: 'https://kambanthemaker.gumroad.com/l/elephas?affiliate_id=908216531',
    email: 'contact@gumroad.com',
    metaDescription: 'AI assistant Elephas helps you write faster, from emails to blogs, to code, and social media posts',
    description: 'AI assistant Elephas helps you write faster, from emails to blogs, to code, and social media posts',
    slug: 'elephas'
  },
  {
    name: 'Github Autopilot',
    longDescription: "<p>Autopilot automates much of the grunt work of coding by delivering snippets of code to developers, learning as it progresses and adapting to evolving coding styles. By using Autopilot, coders are able to write code faster and more accurately</p><p>Coding has been transformed by Autopilot. This system incorporates AI into its design, enabling more efficient and accessible coding.</p><p>Having a faster and more accurate way to write code gives programmers more time to work on other aspects of their projects.</p><p>With Autopilot, there is no limit to the languages or platforms it can support; it can be implemented into any project regardless of size or scope. Even beginners can use its features, since it's designed to be intuitive. It's an ultra-dependable tool that will help streamline your work and help you down the right path to producing high-quality code. AI is coming to coding, so get used to it now. Its user-friendly design, integration of AI, and versatility make it a must-have for anyone serious about a future in the development space.</p>",
    category: 'Coding',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Github-copilot-AI-coding-partner.jpg',
    website: 'https://github.com/features/copilot?ref=spiff.store',
    email: 'contact@github.com',
    metaDescription: 'Autopilot automates much of the grunt work of coding by delivering snippets of code to developers, learning as it progresses and adapting to evolving coding styles',
    description: 'Autopilot automates much of the grunt work of coding by delivering snippets of code to developers, learning as it progresses and adapting to evolving coding styles',
    slug: 'github-autopilot'
  },
  {
    name: 'Luma AI',
    longDescription: "<p>A pioneering app and service developed by Luma Labs, a company based in Palo Alto, California, is Luma AI. Luma AI captures three-dimensional images with stunning photo-realistic detail using a technique called Neural Radiance Fields (NeRF). A similar technology is used in high-end gaming to create graphics almost indistinguishable from reality through ray-tracing. </p><p>No matter what level of expertise you have in AI development or if you're just curious about the technology, Luma's NERF technology allows you to easily and quickly create beautiful 3D images and videos. It makes it simple to get up to speed and start exploring the powerful features of this powerful piece of software thanks to the simple user interface.</p>",
    category: 'Generative Art',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Luma-AI-luma-labs-3d-generation.jpg',
    website: 'https://lumalabs.ai/?ref=spiff.store',
    email: 'contact@lumalabs.ai',
    metaDescription: 'A pioneering app and service developed by Luma Labs, a company based in Palo Alto, California, is Luma AI',
    description: 'A pioneering app and service developed by Luma Labs, a company based in Palo Alto, California, is Luma AI',
    slug: 'luma-ai'
  },
  {
    name: 'Playground AI',
    longDescription: `<p>Create and Edit Images like a pro without being one.</p>
    <p>
A new way to combine real and synthetic images to create stunning works of art and photorealistic images bound only by your imagination.

</p>
    <p></p>
    <p></p>
    <p></p>
    `,
    category: 'Generative Art',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Playground-AI-image-generator.jpg',
    website: 'https://playgroundai.com?ref=spiff.store',
    email: 'contact@playgroundai.com',
    metaDescription: 'Create and Edit Images like a pro without being one.',
    description: 'Create and Edit Images like a pro without being one.',
    slug: 'playground-ai'
  },
  {
    name: 'Caspar AI',
    longDescription:  `<p>A tool for professionals to simplify their workflow by summarizing articles, creating content and sharing insights with stakeholders</p>
    <p>Casper is a Chrome Extension that uses AI to help you quickly understand web pages and PDFs without reading everything. It's great for preparing for exams, researching investments, working on deals, or staying updated on new laws.
</p>
    <p>Casper is designed to help people like analysts, strategists, policymakers, and business developers. It can keep you informed about industry news, help you share your thoughts effectively, and understand new policies and their effects.</p>
    <p>Our goal is to create a product that works with your existing tools, personalizes content for you, and collects data from public sources and your email.

We focus on being simple, flexible, aware of new insights, and helping you take action. We want to help you understand, create, and share insights easily, even if you don't have a lot of technical knowledge.
</p>

<p>We use advanced AI models and software to speed up your work and make your organization more efficient. We collect data from various sources, highlight the most important new insights, analyze the data, and summarize relevant news into short, easy-to-share notes.

With Casper, you can stop wasting time on boring tasks and focus on what's important.

</p>
    
    `,
    category: 'Copywriting',
    imgUrl: 'https://img.youtube.com/vi/qDhyhfAAMCo/hqdefault.jpg',
    website: 'https://chrome.google.com/webstore/detail/casper-ai/fgfiokgecpkambjildjleljjcihnocel?ref=spiff.store',
    email: 'contact@google.com',
    metaDescription: 'A tool for professionals to simplify their workflow by summarizing articles, creating content and sharing insights with stakeholders',
    description: 'A tool for professionals to simplify their workflow by summarizing articles, creating content and sharing insights with stakeholders',
    slug: 'caspar-ai'
  },
  {
    name: 'GetGenie',
    longDescription: `<p>Write SEO Content Adored by
Google to Boost Organic Traffic</p>

<p>Rule the content kingdom with NLP keywords and SERP analysis data. Supercharge SEO with Ai and skyrocket traffic!</p>

<p>With SEO mode, boost your content's SERP readiness from day one with AI-powered optimization with in-depth automated analysis.</p>

<p>Get ahead of your SERP competitors using the Ai power! Analyze their content gaps as well as their strengths and outrank them on the search result pages with winning content assisted by GetGenie.</p>

<p>One-Click AI-powered blog creation in 60 seconds! Write in bulk and effortlessly optimize content for SEO — boost search rankings.</p>
`,
    category: 'Copywriting',
    imgUrl: 'https://getgenie.ai/wp-content/uploads/2023/09/GetGenieAi_Home_Page_Dark_Version_3_OG_Image.png',
    website: 'https://getgenie.ai/?rui=325&campaign=aitools',
    email: 'contact@getgenie.ai',
    metaDescription: 'Write SEO Content Adored by Google to Boost Organic Traffic',
    description: 'Write SEO Content Adored by Google to Boost Organic Traffic',
    slug: 'getgenie'
  },
  {
    name: 'Hotpot',
    longDescription: `<p>Our AI image generator brings imagination to life, producing stunning art, illustrations, and photos in seconds. </p>
    <p>Unleash creativity and express yourself in new ways with the power of AI. Explore endless possibilities, from crafting unique marketing materials to creating beautiful artwork, all with supreme ease and efficiency.</p>
    <p>Elevate your LinkedIn photo, company website, and professional profile with AI Corporate Headshots.</p>
    
    `,
    category: 'Generative Art',
    imgUrl: 'https://hotpot.ai/images/site/ai/image_generator/art_maker/teaser_400.jpg',
    website: 'https://hotpot.ai/art-maker?ref=spiff.store',
    email: 'contact@hotpot.ai',
    metaDescription: 'Our AI image generator brings imagination to life, producing stunning art, illustrations, and photos in seconds. ',
    description: 'Our AI image generator brings imagination to life, producing stunning art, illustrations, and photos in seconds. ',
    slug: 'hotpot'
  },
  {
    name: 'ContentBot',
    longDescription: "<p>ContentBot is the perfect tool for busy content creators and digital marketers looking for ways to streamline their content creation process. With AI-driven content generation, you can quickly create ad copy, blog posts, landing page copy and more with just a few clicks. Summarize articles in an instant.</p><p>There are plenty of options to suit your work style - ppen the web app, click the Chrome extension, or install the WordPress plugin to generate unique and original content wherever you are.</p><p>ContentBot also offers SEO tools to help optimize your writing in no time! </p><p>And it's all available in over 110 languages. Get started today and enjoy the hassle-free way of creating great content!</p><p>Alternatives: SudoWrite, Jasper, Copy AI, Creaitor, Kafkai, WriteSonic</p>",
    category: 'Copywriting',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/ContentBot-paraphraser-content-writer.jpg',
    website: 'https://contentbot.ai/',
    email: 'contact@contentbot.ai',
    metaDescription: 'ContentBot is the perfect tool for busy content creators and digital marketers looking for ways to streamline their content creation process',
    description: 'ContentBot is the perfect tool for busy content creators and digital marketers looking for ways to streamline their content creation process',
    slug: 'contentbot'
  },
  {
    name: 'Google Workspace',
    longDescription: "<p>Google Workspace is a suite of cloud-based productivity and collaboration tools that includes Gmail, Google Drive, Google Docs, Google Sheets, Google Slides, and many other applications. With the advancements in Artificial Intelligence (AI) technologies, Google has been integrating AI capabilities into its Workspace suite to make it more efficient and user-friendly and more suitable for hybrid work life</p><p>Google's Smart Compose uses AI to suggest words and phrases as you type an email in Gmail. The system uses machine learning algorithms to analyze the text you have already typed and then suggest the most relevant words and phrases based on your writing style.</p><p>Google Workspace uses AI-powered grammar suggestions in Google Docs, Google Sheets, and Google Slides</p><p>Voice typing is another AI capability offered by Google Workspace. The system allows users to dictate text using their voice in Google Docs.</p><p>Portrait restore uses Google AI to enhance video quality in Google Meet, even if you're in a dimly lit room or have poor WiFi connection.</p><p>Google's Smart Reply is an AI-powered feature that suggests responses to emails in Gmail.</p><p>The Explore feature is an AI-powered tool that helps users find information and insights in Google Sheets, Google Docs, and Google Slides.</p><p>Google Meet now has an AI-powered live transcription feature that transcribes the audio in real-time.</p>",
    category: 'Productivity',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Google-Workspace-and-AI.jpg',
    website: 'https://workspace.google.com/landing/partners/referral/gws2.html?utm_source=sign-up&utm_medium=affiliatereferral&utm_campaign=apps-referral-program&utm_content=4T3GHG6',
    email: 'contact@google.com',
    metaDescription: 'Google Workspace is a suite of cloud-based productivity and collaboration tools that includes Gmail, Google Drive, Google Docs, Google Sheets, Google Slides, and many other applications',
    description: 'Google Workspace is a suite of cloud-based productivity and collaboration tools that includes Gmail, Google Drive, Google Docs, Google Sheets, Google Slides, and many other applications',
    slug: 'google-workspace'
  },
  {
    name: 'Craiyon',
    longDescription: `
    <p>Create AI Art with our free AI image generator.</p>
    <p>Imagine having your own personal genie to draw anything that comes to mind, whether you're a complete beginner or experienced artist. With Craiyon, there's no need to imagine!</p>
    <p>Previously known as DALL·E mini, Craiyon is the new kid on the tech block and the best AI art generator (in our community's words!). The brainchild of our CEO, lead researcher, and AI hero, Boris Dayma, Craiyon is a free AI image generator that's painting a new generation for the AI art revolution through our own model. Since 2022 (has it really been a year already…?) we've been ushering in the next era of AI image generation.</p>
    <p>If you can dream it, Craiyon can draw it. Thanks to our advanced in-house-developed tech, a simple text prompt is all Craiyon needs to turn your imagination into AI reality - in seconds.

But this genie gives more than 3 wishes. Get 9 free images at a time within a minute or go pro for unlimited art, fewer ads, and faster generation. Let your imagination run wild!</p>
    <p>Sushi painted by van Gogh? Your wish is our command. Gandhi as a Dragon Ball Z battle card? Granted.

Anything goes with Craiyon in your AI art tool box, from abstract art, aesthetic wallpapers, to breathtaking AI landscapes. Explore infinite possibilities for styles, themes, and techniques, and most importantly, have FUN exploring the boundless possibilities of AI generated images with us!

We're constantly improving and updating our state-of-the-art technology to improve your Craiyon experience on the daily. We can't wait to have you on-board to ride the AI wave with us.</p>
    <p>Craiyon and ChatGPT work in cahoots to bring you the best AI drawing prompts this side of the web.</p>
    
    `,
    category: 'Generative Art',
    imgUrl: 'https://www.craiyon.com/craiyon_preview2.png',
    website: 'https://www.craiyon.com/?ref=spiff.store',
    email: 'contact@craiyon.com',
    metaDescription: 'Create AI Art with our free AI image generator.',
    description: 'Create AI Art with our free AI image generator.',
    slug: 'craiyon'
  },
  {
    name: 'SocialBee',
    longDescription: "<p>SocialBee's AI-powered post generator creates social media posts based on your preferred topics and keywords, saving you time and effort, and helps to keep a consistent and impactful social media presence. </p><p>The AI-powered tech also analyzes inputs and uses generative AI algorithms to smash out social media posts in line your brand's voice and tone. A bit like having a co-author who knows your brand inside and out. Get a week's worth of on-brand social media posts in a few clicks. It makes content creation easier and more efficient.</p><p>Socialbee works with Facebook, Twitter, LinkedIn, Instagram, Pinterest, Google My Business, TikTok, and YouTube. With Canva, stock photos, and GIPHY integrations, you can make beautiful posts directly on the platform and leverage AI to improve your original content. Make viral social content with simple English language prompts.</p>",
    category: 'Social Media',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/SocialBee-AI-captions-and-Image-generation-for-social-media.jpeg',
    website: 'https://socialbee.com/ai-post-generator/?utm_source=growsumo&dst=partners&utm_source=growsumo&pscd=get.socialbee.io&ps_partner_key=ZmZt&ps_xid=DEYP4n8mJkQ7Xc&gsxid=DEYP4n8mJkQ7Xc&gspk=ZmZt&touched=true',
    email: 'contact@socialbee.com',
    metaDescription: "SocialBee's AI-powered post generator creates social media posts based on your preferred topics and keywords, saving you time and effort, and helps to keep a consistent and impactful social media presence",
    description: "SocialBee's AI-powered post generator creates social media posts based on your preferred topics and keywords, saving you time and effort, and helps to keep a consistent and impactful social media presence",
    slug: 'socialbee'
  },
  {
    name: 'Kafkai',
    longDescription: `
    <p>Powerful and Easy-to-Use AI Content Writer</p>
    <p>Kafkai is an AI Content Writer that helps you create bulk, multilingual articles with just a few clicks.

</p>
    <p>Instantly create compelling articles with a single click. One-click generation simplifies your creations, allowing you to easily access high-quality content.</p>
    <p>Tailor your articles to specific topics using our keyword-based generation. Just type the keywords, and watch our AI write engaging content based on your preferences.
</p>
    <p>Boost your online presence with SEO-optimized articles. Our AI strategically enhances your content for search engine visibility. Now it's easier for your audience to discover your work.</p>
    <p>Empower your writing process with advanced keyword research tools. Find trending keywords and make sure your content is what your audience is looking for.</p>
    <p>Enhance your articles with automated image integration. Our AI adds relevant visuals to complement your content. Now it's also a visually appealing and comprehensive reading experience.</p>
    <p>Craft comprehensive, in-depth articles exceeding 3000 words easily. Our AI is capable of creating lengthy, informative content that captivates your audience. Make sure you establish your expertise in your chosen niche.</p>
    <p>Reach a global audience by generating articles in multiple languages. Create content that resonates with diverse audiences around the world.</p>
    <p>Save time and effort with bulk article generation. Generate multiple articles at once and scale up. It works whether you're managing a blog, website, or content marketing strategy.</p>
    `,
    category: 'Copywriting',
    imgUrl: 'https://kafkai.com/static/images/social/kafkai.png',
    website: 'https://kafkai.com/en/',
    email: 'contact@kafkai.com',
    metaDescription: 'Powerful and Easy-to-Use AI Content Writer',
    description: 'Powerful and Easy-to-Use AI Content Writer.',
    slug: 'kafkai'
  },
  {
    name: 'AI Room Planner',
    longDescription: `
    <p>Get hundreds of interior design ideas for your room - free with no limit.</p>
    <p>Now AI can help you do interior design on your own. You can get hundreds ideas of how your room could be in various design styles.</p>
    <p>Currently our product is in beta testing so you can use the Interior AI Design free with no limit. However, this could be changed in the future without notification. So let's go and see how AI can bring tons of ideas for your room.</p>
    <p>AI is a great tool to generate interior design ideas. It can give you an idea of how a space could look and feel like.</p> 
    `,
    category: 'Generative Art',
    imgUrl: 'https://airoomplanner.com/public/themes/roomplanner/assets/img/hero-img.png',
    website: 'https://airoomplanner.com/?ref=spiff.store',
    email: 'contact@airoomplanner.com',
    metaDescription: 'Get hundreds of interior design ideas for your room - free with no limit.',
    description: 'Get hundreds of interior design ideas for your room - free with no limit.',
    slug: 'ai-room-planner'
  },
  {
    name: 'Midjourney',
    longDescription: `
    <p>Midjourney is an independent research lab exploring new mediums of thought and expanding the imaginative powers of the human species.</p>
    <p>We are a small self-funded team focused on design, human infrastructure, and AI. We have 11 full-time staff and an incredible set of advisors.
</p>
    `,
    category: 'Generative Art',
    imgUrl: 'https://valasys.com/wp-content/uploads/2023/07/What-Is-Midjourney-AI-and-How-Does-It-Work-1.jpg',
    website: 'https://www.midjourney.com/?ref=spiff.store',
    email: 'contact@midjourney.com',
    metaDescription: 'Midjourney is an independent research lab exploring new mediums of thought and expanding the imaginative powers of the human species.',
    description: 'Midjourney is an independent research lab exploring new mediums of thought and expanding the imaginative powers of the human species.',
    slug: 'midjourney'
  },
  {
    name: 'AISEO',
    longDescription: `
    <p>Beat Your Competition at
Their Own Game!</p>
    <p>Craft SEO-optimized articles, modeled after top-performing content on Google, to strategically dominate your niche and boost your rankings.</p>
    <p>While ChatGPT creates general blog content, Aiseo specializes in crafting introductory sections for blog articles that showcase domain expertise, positioning the writer as a pioneer in their field.</p>
    <p>As AI-driven content becomes more widespread, search engines like Google employ their E-E-A-T guidelines (Experience, Expertise, Authoritativeness, Trustworthiness) to evaluate content caliber.</p>
    <p>Aiseo is adeptly calibrated to produce content aligned with Google’s E-E-A-T standards, increasing the potential for achieving favorable search rankings.</p>
    `,
    category: 'Copywriting',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/AISEO-AI-copywriting-assistant.jpeg',
    website: 'https://art.aiseo.ai/',
    email: 'contact@aiseo.ai',
    metaDescription: 'Beat Your Competition at Their Own Game!',
    description: 'Beat Your Competition at Their Own Game!',
    slug: 'aiseo'
  },
  {
    name: 'Lexica',
    longDescription: `
    <p>The state of the art AI image generation engine.</p>
    `,
    category: 'Generative Art',
    imgUrl: 'https://lexica.art/lexica-meta.png',
    website: 'https://lexica.art/?ref=spiff.store',
    email: 'contact@lexica.art',
    metaDescription: 'The state of the art AI image generation engine.',
    description: 'The state of the art AI image generation engine.',
    slug: 'lexica'
  },
  {
    name: 'Riku',
    longDescription: `
    <p>Build AI Apps in Minutes, with No-code</p>
    <p>Build AI, Experiment, Deploy - User Feedback Approved.
Embed Generative AI Workflow in Your Business with No-Code!</p>
    <p>Build GPT Apps, Chat Apps and Prompt Apps with your own data. 
Construct advanced AI solutions without limits. Our no-code platform lets you build and deploy powerful generative AI for your business with ease.</p>
    <p>Our platform allows you to connect siloed data sources and systems together to feed into powerful AI applications. Integrate data securely and maintain governance.</p>
    <p>With access to over 40 industry-leading LLMs, you can easily test different prompts to find just the right one for your needs. Our prompt building toolkit allows swift experimentation so you can optimize prompts with confidence.
</p>
    <p>Embedding your AI into your chat tools, prompt apps, workflows, websites and more to interact with customers intelligently.</p>
    `,
    category: 'Copywriting',
    imgUrl: 'https://assets-global.website-files.com/6423051caaa7d12552c2de54/6556f17ed7da3acb71550c67_OG.png',
    website: 'https://www.riku.ai/?ref=fatfrogmedia',
    email: 'contact@riku.ai',
    metaDescription: 'Experiment, test and deploy AI Applications without code. Build chat, text, vision and image apps in a simple-to-use interface with Riku.AI',
    description: 'Experiment, test and deploy AI Applications without code. Build chat, text, vision and image apps in a simple-to-use interface with Riku.AI',
    slug: 'riku'
  },
  {
    name: 'Todoist AI Assistant',
    longDescription: "<p>Todoist is a popular task management app that has helped millions of people stay organized and get things done. With the addition of an AI assistant, you can take your 'to do list' productivity to level 11. </p><p>Todoist is one of the world's most popular task managers and new AI features can make goals more attainable by generating a list of tasks, making tasks more actionable, breaking down intimidating tasks, and offering tips on how to achieve tasks. </p><p>If you're someone who constantly forgets to do things or struggles with prioritization, then an AI assistant might just be the answer.</p>",
    category: 'Productivity',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Todoist-AI-assistant.jpg',
    website: 'https://todoist.com/integrations/apps/ai-assistant?utm_source=partnerstack&utm_medium=resellers&utm_content=ffm&pscd=get.todoist.io&ps_partner_key=ZmZt&ps_xid=DIV3u3LRXhosoV&gsxid=DIV3u3LRXhosoV&gspk=ZmZt',
    email: 'contact@todoist.com',
    metaDescription: 'Todoist is a popular task management app that has helped millions of people stay organized and get things done',
    description: 'Todoist is a popular task management app that has helped millions of people stay organized and get things done',
    slug: 'todoist-ai-assistant'
  },
  {
    name: 'Soofy',
    longDescription: `
    <p>From Now on, You Don't Have to Feel Embarrassed, Train Your Tongue to Speak Fluently</p>
    <p>With Soofy, you can learn how to use your new language effectively in everyday conversations. You'll quickly gain the skills you need, such as debating, discussing ideas and books. With all of these options, you'll be able to select a topic of your choice and have meaningful conversations in no time.</p>
    <p>With Soofy, you can quickly and easily learn new expressions and words commonly used in the language you are trying to learn, along with their definitions and examples, as well as translations to your native language. You can also write your own examples and our advanced AI will provide instant feedback to help you perfect the language.</p>
    <p>With Soofy, you can improve your writing by making use of the words you have learned, by describing a picture, writing essays and our AI will provide instant feedback to help you improve your level.</p>
    <p>With Soofy, you can ask our AI to write emails, articles, generate ideas and assist you with your writing needs. Our AI will be your trusted assistant.</p>
    <p>Don't Let Language Barriers Hold you Back!</p>
    `,
    category: 'Education',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Soofy-AI-language-learning-software.jpg',
    website: 'https://soofy.io/index/ref?&r=1677530307487x221487952293727200',
    email: 'contact@soofy.io',
    metaDescription: 'From Now on, You Don\'t Have to Feel Embarrassed, Train Your Tongue to Speak Fluently',
    description: 'From Now on, You Don\'t Have to Feel Embarrassed, Train Your Tongue to Speak Fluently',
    slug: 'soofy'
  }
]

// export default TOOLS;
module.exports = TOOLS;
