const TOOLS = [
  {
    name: 'ContentStudio AI Bot',
    longDescription: "<p>Make social media content creation much easier to manage with the one of the best content scheduling, content discovery, and content creation tool.</p><p></p><p>Write engaging captions, fix grammar errors, and even add relevant emojis—all at the click of a button. This will help you maintain a consistent online presence so that your social media profiles are always active</p><p>Create images that resonate with a brand's style and aesthetic, which is crucial for social media success. This feature makes it easier than ever to create compelling visuals</p><p>Use the AI writer to make your existing content better, create new text from scratch based off of keywords and phrases you provide, summarize a URL's main points into writing.</p><p>Boost your social media posts' visibility by adding relevant and trending hashtags. And here's the best part—you don't need a separate tool for finding those hashtags, because ContentStudio has got you covered right within its AI Bot.</p>",
    category: 'Social Media',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/ContentStudio-Social-Media-AI-writer.jpg',
    website: 'https://contentstudio.io/ai-writer',
    email: 'contact@contentstudio.io',
    metaDescription: 'Make social media content creation much easier to manage with the one of the best content scheduling, content discovery, and content creation tool',
    description: 'Make social media content creation much easier to manage with the one of the best content scheduling, content discovery, and content creation tool',
    slug: 'contentstudio-ai-bot'
  },
  {
    name: 'Gling',
    longDescription: "<p>Save time for pure creativity and content creation with Gling AI</p><p>Gling automates the trimming process by identifying and cutting out lackluster sections in your raw video or audio files. Gling's Artificial Intelligence and Machine Learning algorithms crawl through your raw recorded content, skillfully eliminating unwanted silences and incorrect takes. </p><p>Compatible with major editing tools such as Final Cut Pro, DaVinci Resolve, Adobe Premiere, and others. However, if required, an MP4 or MP3 file can also be exported. You can export an XML from Gling and directly import it as a timeline to Premiere, Final Cut Pro, or DaVinci Resolve. If you're using a different video editor, no worries. Export from Gling as an mp4 and import to any editor.</p><p></p><p>Upload Raw Recording</p><p>Edit and Review</p><p>Export</p><p></p><p>While other video editing tools necessitate vast amounts of your time in cutting and editing, Gling's AI functionality alleviates a major chunk of this manual work. You might question the accuracy of its AI, but the incorporated review mechanism ensures a balance between AI effectiveness and human intuition.</p>",
    category: 'Video Editing',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Gling-AI-Powered-video-editing.jpg',
    website: 'https://www.gling.ai/',
    email: 'contact@gling.ai',
    metaDescription: 'Save time for pure creativity and content creation with Gling AI\n' +
      'Gling automates the trimming process by identifying and cutting out lackluster sections in your raw video or audio files',
    description: 'Save time for pure creativity and content creation with Gling AI\n' +
      'Gling automates the trimming process by identifying and cutting out lackluster sections in your raw video or audio files',
    slug: 'gling'
  },
  {
    name: 'Bodt',
    longDescription: "<p>Bodt is an AI chatbot that develops customer service and engagement on your website.</p><p>Features</p><p>-24/7 Multilingual AI Support: Bodt excels in generating accurate responses to complex conversations, ensuring your customers receive the support they need around the clock and in multiple languages.-Proactive Engagement: Enhance the user experience by proactively recommending products and collecting leads. -Enhanced Visibility: Bodt provides specific information, giving visibility to your website's content and products.-Conversational Knowledge Hub: Bodt acts as a centralized hub for all important information about your organization. -Log-In for Employees and Complete Data Security: Ensure data protection and encryption with Bodt's secure log-in feature. -Optimize Operations and Data Analytics: Leverage Bodt's insightful analytics to craft effective strategies and identify areas for improvement.</p>",
    category: 'Productivity',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Bodt-AI-Chatbot-Tool.jpg',
    website: 'https://www.bodt.io/',
    email: 'contact@bodt.io',
    metaDescription: 'Bodt is an AI chatbot that develops customer service and engagement on your website',
    description: 'Bodt is an AI chatbot that develops customer service and engagement on your website',
    slug: 'bodt'
  },
  {
    name: 'Sharp API',
    longDescription: '<p>SharpAPI presents itself as an AI-powered Swiss Army Knife API, designed to enhance the capabilities of software developers by integrating a variety of tools and functionalities. </p><p>Key Features of SharpAPI:</p><p></p><p>AI Integration: SharpAPI allows for the integration of AI capabilities into applications with minimal coding effort.</p><p>Diverse Applications: It caters to various sectors including E-commerce, HR Tech, Travel, Tourism & Hospitality, and Content & Marketing Automation.</p><p>Content Analysis and Generation: The API aids in tasks like product description generation, sentiment analysis, and spam content detection.</p><p>Language Support: Supports translations in 80 languages, making it versatile for global applications.</p><p>Data Formats and Documentation: Offers clean, consistent JSON data formats and extensive documentation for ease of use.</p><p>Developer Support: Provides technical support and is crafted with a developer-focused approach.</p>',
    category: 'Coding',
    imgUrl: 'https://assets.fatfrogmedia.com/file/aitools/Sharp-API-for-developers.jpg',
    website: 'https://sharpapi.com/ref/sharpapi_pzdl25ai?ref=spiff.store',
    email: 'contact@sharpapi.com',
    metaDescription: 'SharpAPI presents itself as an AI-powered Swiss Army Knife API, designed to enhance the capabilities of software developers by integrating a variety of tools and functionalities',
    description: 'SharpAPI presents itself as an AI-powered Swiss Army Knife API, designed to enhance the capabilities of software developers by integrating a variety of tools and functionalities',
    slug: 'sharp-api'
  }
]

// export default TOOLS;
module.exports = TOOLS;
