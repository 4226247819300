const TOOLS = [
  {
    name: 'Recast',
    longDescription: "<p>Recast offers a novel solution to information overload online. Sift through an avalanche of content online by converting the written content to audio podcasts.</p><p></p><p>Recast can help you consume your lengthy backlog of articles, thought pieces, and reports. It will digest the content and relay it back to you in a coherent, engaging podcast show—with human touch!</p><p>After a short period, your article is converted into an audio conversation between the app's AI hosts—who provide analysis and discussion of what they've read.</p><p>Straightforward functionality & user-friendly interface - choose an article or a piece you would like to understand and submit it through their app or browser extensions.</p><p>Recast's library boasts a colossal collection of trending and top-rated articles that have been 'recasted' by the community.</p><p>Mirroring popular apps' AI-curated playlists, Recast crafts a personalized queue of articles that align with your interests, ensuring relevance and guaranteeing user engagement.</p><p></p><p>Audible may be an audiobook giant, but its linear narrative style lacks the dynamic conversation that Recast brings to the table.</p>",
    category: 'Text-to-Voice',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Recast-AI-audio-from-text.jpg',
    website: 'https://www.letsrecast.ai/?ref=spiff.store',
    email: 'contact@letsrecast.ai',
    metaDescription: 'Recast offers a novel solution to information overload online',
    description: 'Recast offers a novel solution to information overload online',
    slug: 'recast'
  },
  {
    name: '10Web',
    longDescription: '<p>The 10Web AI Assistant is a quicker, easier way to create and edit content in WordPress. This AI website builder generate unique, SEO-optimized website content that is indistinguishable from human writing. Plus, it comes with plenty of helpful features such as generators for various types of content and a paraphrase generator - perfect for saving time and resources</p><p>The plugin has an easy to navigate dashboard that lets you to create, edit and optimize your content with a click. </p><p>Not only does it make creating content simpler, but it also works to improve readability, style and creativity. There are over 20 templates with tailored prompts on offer so you can get the best results possible. All this comes within the 10Web Dashboard which includes its own Document Editor - ideal for streamlining the whole process even further.</p><p>Add-ons for major WP plugins are also available. </p><p>Suitable for WordPress pros and beginners. </p><p>Alternatives: GetGenie</p>',
    category: 'Marketing',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/10Web-AI-Assistant-for-WordPress.jpeg',
    website: 'https://10web.io/ai-website-builder/?_from=ffmedia',
    email: 'contact@10web.io',
    metaDescription: 'The 10Web AI Assistant is a quicker, easier way to create and edit content in WordPress',
    description: 'The 10Web AI Assistant is a quicker, easier way to create and edit content in WordPress',
    slug: '10web'
  },
  {
    name: 'Codeleaks',
    longDescription: '<p>Codeleaks is a source code AI detector developed by Copyleaks, one of the leading tools in plagiarism detection for written content.</p><p></p><p>Stay one step ahead of potential risks by providing detection of AI-generated code, checking for instances of plagiarism and modifications, and offering essential licensing details. Mitigate licensing and copyright infringement risks.</p><p>Get the true origin story of any code. Codeleaks is the only tool on the market that can detect plagiarism and AI-generated code with up to 99.8% precision.</p><p>Stay out of copyright trouble with licensing detail alerts. The software searches across popular code repositories like GitHub and Stack Overflow, helping you avoid copyright trouble by flagging potentially restricted code.</p><p>Keep ownership of your code. For proprietary code holders, Codeleaks provides constant monitoring and immediate alerts for potential infringements or leaks, adding a layer of security to your code. Discover if the source code has been copied or modified. Codeleaks accounts even for minor alterations or changes to larger sections.</p><p>Enjoy code highlighting for many programming languages. Each scan by Codeleaks reads the code as individual tokens, offering accurate, detailed results tailored to the unique coding language. Languages supported are Ruby, JavaScript, TypeScript, Python, C++, C, Java, C# and PHP.</p><p>Integrate code plagiarism detection with your native LMS for seamless plagiarism detection. Get the benefits of a fully customizable API for tailoring source code detection to your needs.</p>',
    category: 'Coding',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Codeleaks-AI-code-plagiarism-detection-tool.jpg',
    website: 'https://copyleaks.com/codeleaks',
    email: 'contact@copyleaks.com',
    metaDescription: 'Codeleaks is a source code AI detector developed by Copyleaks, one of the leading tools in plagiarism detection for written content',
    description: 'Codeleaks is a source code AI detector developed by Copyleaks, one of the leading tools in plagiarism detection for written content',
    slug: 'codeleaks'
  },
  {
    name: 'Trinka AI',
    longDescription: "<p>Trinka is a word-wrangling, syntax-slinging AI sidekick for academic and technical writing. Trinka sniffs out those hard-to-spot errors that traditional grammar checkers might miss. Great for advanced English grammar gaffes and scientific style snafus. Watch your writing transform from drab to fab!</p><p>Trinka finds difficult errors unique to academic writing that other grammar checker tools don't. From advanced English grammar errors to scientific tone and style.</p><p>A specialized grammar checker and language enhancement tool designed for academic and technical writing, ensuring precision and professionalism across all subjects. Trinka's algorithm has been trained using academic papers from various fields so it has the authority to suggest relevant corrections and suggestions tailored to your subject area. </p><p>Trinka goes beyond basic grammar and spelling corrections to ensure that your writing is both polished and precise. It also includes a plagiarism checker. Free for up to 10,000 words per month.</p><p>Alternatives: Grammarly, Quillbot, Linguix</p>",
    category: 'Productivity',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Trinka-ai-grammar-checker.jpeg',
    website: 'https://www.trinka.ai/?sscid=51k8_evs7a',
    email: 'contact@trinka.ai',
    metaDescription: 'Trinka is a word-wrangling, syntax-slinging AI sidekick for academic and technical writing',
    description: 'Trinka is a word-wrangling, syntax-slinging AI sidekick for academic and technical writing',
    slug: 'trinka-ai'
  },
  {
    name: 'Audio writer',
    longDescription: '<p>[Audio Writer] is a speech-to-text software that helps you save time in writing your content. Audio Writer is a tool that will change the way you create written content.</p><p>Features</p><p>-Refine Transcripts: Enjoy clean and error-free transcripts without the "ums" and "ahs" and improved grammar and punctuation.-Rewrite in Different Styles: Change the tone and style of your text in a variety of styles or even mimic the writing styles of various authors.-Pre-Defined and Custom Presets: Select from a range of pre-defined templates or design your own custom preset to rewrite your transcript.-15+ Languages Supported: Speak in your language and get a transcript in your desired language.-Repurpose in Different Formats: Generate content for emails, social media platforms, action items, and drafts.</p>',
    category: 'Productivity',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Audio-Writer-Voice-to-Text-Tool.jpg',
    website: 'https://audiowriter.app?ref=spiff.store',
    email: 'contact@audiowriter.app',
    metaDescription: '[Audio Writer] is a speech-to-text software that helps you save time in writing your content',
    description: '[Audio Writer] is a speech-to-text software that helps you save time in writing your content',
    slug: 'audio-writer'
  },
  {
    name: 'Softr AI',
    longDescription: "<p>Softr AI is an application generator tool that makes the process of building an app simpler. The tool is designed for all types of businesses, including developers and marketers. It also has a drag-and-drop interface that makes it easy for anyone to use.</p><p>Create anything from a client portal to an internal tool, directory, or membership platform. It's versatile and easy to use thanks to the heavy lifting performed by AI and Softr's solid platform.</p><p>Data sync with Google Sheets is automatic, and no extra process or setup is needed—the data sync happens seamlessly.</p><p>You can get going with a powerful app in 5 steps. Here's how:</p><p>1. Set up Your Data: Applications generated by Softr AI come pre-connected with data in Google Sheets. You have the flexibility to personalize this data as per your app's needs. </p><p>2. Edit Your App: design your app layout, access various tools, and build functional pages using the design studio.</p><p>3. Define User Roles: customize everything about user roles within the application. </p><p>4. Check App Settings: Before hitting the final publish button, you can review and tweak the app's settings for the polished final touch.</p><p>5. Preview and Publish: Preview for a final look, and then click Publish to make your app live.</p>",
    category: 'NoCode',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Softr-AI-app-generator-tool.jpg',
    website: 'https://www.softr.io/ai-app-generator?utm_source=ffm&utm_medium=affiliate&campaign=new_affiliates&ps_partner_key=ZmZt&ps_xid=voic5dN9A2uKTL&gsxid=voic5dN9A2uKTL&gspk=ZmZt',
    email: 'contact@softr.io',
    metaDescription: 'Softr AI is an application generator tool that makes the process of building an app simpler',
    description: 'Softr AI is an application generator tool that makes the process of building an app simpler',
    slug: 'softr-ai'
  },
  {
    name: 'Rizzle',
    longDescription: "<p>Rizzle is a platform for creating videos driven by AI that enables you to produce spectacular videos in a matter of minutes with no editing. Transform text, podcasts, prompts, blogs, tweets, and URL's into highly engaging videos. Simply add your text and, optionally, your media, and let Rizzle do the rest.</p><p></p><p>AI Video Creation: Generate beautiful videos produced with straightforward inputs. Create eye-catching films in a couple of minutes from common text, links, and files. </p><p>Customization at Your Fingertips:  Your movies can be altered to reflect your business or personal preferences. From choosing the perfect icons and styles to selecting actions and layouts that fit your vision, Rizzle gives you the freedom to create a unique look and feel that's truly your own.</p><p>Seamlessly Integrate with Ease: Rizzle can be integrated with your website or mobile app. Embed Rizzle into your platforms effortlessly. You'll have the ability to effortlessly create and distribute your videos seamlessly, reaching your audience wherever they are.</p><p>Effortless Distribution, Maximum Reach: Your videos can be shared on any social networking website. Schedule your material and let Rizzle post it for you automatically to stay one step ahead of the competition.</p><p>Measure and Optimize: Rizzle additionally offers in-depth statistics on your videos, enabling you to comprehend their significance and efficacy</p>",
    category: 'Text-to-Video',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Rizzle-create-videos-from-prompts.jpg',
    website: 'https://rizzle.com/',
    email: 'contact@rizzle.com',
    metaDescription: 'Rizzle is a platform for creating videos driven by AI that enables you to produce spectacular videos in a matter of minutes with no editing',
    description: 'Rizzle is a platform for creating videos driven by AI that enables you to produce spectacular videos in a matter of minutes with no editing',
    slug: 'rizzle'
  },
  {
    name: 'Autoblogging AI',
    longDescription: "<p>Autoblogging was created with content creators, bloggers, and e-commerce enthusiasts in mind. Its primary objective is the cost-effective and efficient generation of first drafts for your content, helping you scale your website with a focus on organic traffic.</p><p>The tool is updated and enhanced regularly so you're always getting the latest algorithmic features to help you create the best content on the web.</p><p></p><p>Three generation modes for varying needs: Quick Mode, Pro Mode, and Godlike Mode.</p><p>Generate highly readable, engaging, and 100% original content or receive a credit refund on unsatisfactory outputs. </p><p>Supports 20 languages, including English, French, Spanish, Chinese, Japanese, and Arabic</p><p>Bulk generation for efficient content management</p><p>Automatic WordPress posting service for seamless integration</p><p>Create accurate reviews of Amazon products by using their URLs as input.</p><p></p><p>The unique Godlike Mode goes a step further than mere generative content creation by generating articles that consistently score well on SEO tools such as Surfer SEO, making sure that your content beats everyone else in organic search.</p>",
    category: 'Copywriting',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Autoblogging-AI-article-writer-tool.jpg',
    website: 'https://autoblogging.ai/?sld=ffmedia',
    email: 'contact@autoblogging.ai',
    metaDescription: 'Autoblogging was created with content creators, bloggers, and e-commerce enthusiasts in mind',
    description: 'Autoblogging was created with content creators, bloggers, and e-commerce enthusiasts in mind',
    slug: 'autoblogging-ai'
  },
  {
    name: 'AimReply',
    longDescription: "<p>AimReply is an AI email writer and content assistant that offers to help everybody improve their written content. Whether you're in sales, a customer support hero, a job hunter, a creative mind, or running your own business, AImReply improves the quality of your written content. It can effectively boost productivity for you, your team, and your organization.</p><p>🚀 For the Sales Gurus and Marketing Whizzes: Send emails that not only get opened, but also get you the results you want. Craft emails that are so engaging, people can't help but take notice. </p><p>💌 Customer Support Superstars: Fix issues and answer queries at speed, making your customers feel truly valued. This is the key to customer happiness.</p><p>🌟 Job Seekers and Recruiters: Stand out in that crowded inbox. Write cover letters and follow-ups that are so compelling that they demand attention. </p><p>✍️ Content Creators, Unite: Whether you're a blogger, writer, or content creator, communicating with editors and readers is a breeze with AImReply. Switch on your own personal assistant who knows exactly what to say and how to say it.</p><p>📈 Entrepreneurs and Small Business Owners: Focus on growing your business, and let AImReply handle the boring emails. From scheduling to responding to customer feedback, you'll always be there for your customers. The tool uses AI to learn from your writing style and preferences so communication is always in your voice.</p>",
    category: 'Marketing',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/AimReply-email-writing-tool.jpg',
    website: 'https://aimreply.com/?ref=spiff.store',
    email: 'contact@aimreply.com',
    metaDescription: 'AimReply is an AI email writer and content assistant that offers to help everybody improve their written content',
    description: 'AimReply is an AI email writer and content assistant that offers to help everybody improve their written content',
    slug: 'aimreply'
  },
  {
    name: 'PDF ai',
    longDescription: "<p>PDF.ai offers a unique, chat-based approach to interacting with PDF documents. Think of it as your PDF assistant.</p><p>Interact with a PDF as though you are having an in-person conversation. Get clarifications, summaries, and responses to your queries.</p><p>The chat interface facilitates seamless knowledge exchange and collaboration on scientific papers. This helps researchers to efficiently share information with teams and editors.</p><p>Other features include the ability to simplify complex legal jargon (save on lawyer fees), understand your gadgets better (lose the boring manual), make training documents more fun for employees (lose the dull training manual), and aid in decision-making on financial documents (get rid of the jargon).</p><p>Easy to use:</p><p></p><p>Easy document upload: Select the PDF you wish to interact with, and you're good to go.</p><p>Get Instant answers: Ask, extract, and summarize information using the powerful AI at its core.</p><p>Source Cited: Each response comes cited, ensuring the reliability of the information delivered.</p><p></p><p>The convenience of the product lies in its simplicity: you upload a document and start a conversation with it. This chatting isn't just a gimmick; the underlying AI impressively fetches any relevant information from the document and provides a comprehensive answer, well cited from the uploaded text. It also works in multiple languages and can answer in a different language to the source.[[</p>",
    category: 'Productivity',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/PDF-ai-chat-with-any-PDF-using-AI.jpg',
    website: 'https://pdf.ai/',
    email: 'contact@pdf.ai',
    metaDescription: 'PDF.ai offers a unique, chat-based approach to interacting with PDF documents. Think of it as your PDF assistant.',
    description: 'PDF.ai offers a unique, chat-based approach to interacting with PDF documents. Think of it as your PDF assistant.',
    slug: 'pdf-ai'
  },
  {
    name: 'RankIQ',
    longDescription: "<p>RankIQ is a comprehensive SEO Toolset for Bloggers and Businesses</p><p>Find low competition, high traffic keywords in every niche and create content that ranks on Google's 1st page.</p><p>Construct blog post outlines in minutes with SEO content briefs tailored to Google's ranking algorithm.</p><p>The Content Optimizer's writing assistant guides you to create comprehensive, 1st page-ranking content.</p><p>Optimize old posts for significant traffic gains in less than 30 minutes.</p><p>Access hand-picked keyword libraries for every major blog niche, filled with low competition, high traffic phrases.</p><p>It's like having a personal trainer for your blog, telling you what to do and when to do it, but without the sweat and sore muscles.</p><p>Alternatives: SurferSEO, Frase, InLinks</p>",
    category: 'SEO',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/RankIQ-AI-SEO-software-tool-square.png',
    website: 'https://www.rankiq.com/',
    email: 'contact@rankiq.com',
    metaDescription: 'RankIQ is a comprehensive SEO Toolset for Bloggers and Businesses\n' +
      "Find low competition, high traffic keywords in every niche and create content that ranks on Google's 1st page",
    description: 'RankIQ is a comprehensive SEO Toolset for Bloggers and Businesses\n' +
      "Find low competition, high traffic keywords in every niche and create content that ranks on Google's 1st page",
    slug: 'rankiq'
  },
  {
    name: 'Nara',
    longDescription: '<p>Nara improves customer support and engagement. It also enhances customer engagement, increases retention, and boosts satisfaction.</p><p>70% of support and sales inquiries can be answered by Nara. </p><p>Features:</p><p></p><p>Instantly responds to consumer questions to ensure they get the help they need right away.</p><p>Automated conversations allow your team to focus on more complex tasks.</p><p>Support customers via a range of channels, such as SMS and email.</p><p>A user-friendly self-service dashboard that allows you to personalize and administer your sources. </p><p>Can be launched on multiple platforms where your customers engage, ensuring a seamless and responsive user experience.</p>',
    category: 'Chat',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Nara-customer-support-ai-tool.jpg',
    website: 'https://trynara.com/?ref=aitools',
    email: 'contact@trynara.com',
    metaDescription: 'Nara improves customer support and engagement',
    description: 'Nara improves customer support and engagement',
    slug: 'nara'
  },
  {
    name: 'Bertha AI',
    longDescription: "<p>Bertha AI is a WordPress plugin that's designed to assist with content creation and SEO. It's a tool aimed at entrepreneurs, bloggers, and anyone who wants to optimize their online content without hiring a full-time writer.</p><p>Bertha AI is designed to aid with SEO, providing keyword suggestions, title tags, and description tags. This can help improve the visibility of your content on search engines.</p><p></p><p>Chrome users can download an extension and a lite version of the website for optimal performance and a seamless experience.</p><p>Use the best-in-class AI image generators to create mesmerizing visuals for your site in seconds</p><p>Write product descriptions that get customers to buy and visitors to stay on your site.</p><p>Leverage AI to create mesmerizing visuals for your site in seconds</p><p>Unlimited users, unlimited websites, and access to Chrome extension. 1 million words per month with 20 image generations per month. Priority support for 7 days money back.</p>",
    category: 'Copywriting',
    imgUrl: 'https://bertha.ai/wp-content/uploads/2024/03/Bertha_web_header.png',
    website: 'https://bertha.ai/?campaign=aitools&ref=7685',
    email: 'contact@bertha.ai',
    metaDescription: "Bertha AI is a WordPress plugin that's designed to assist with content creation and SEO",
    description: "Bertha AI is a WordPress plugin that's designed to assist with content creation and SEO",
    slug: 'bertha-ai'
  },
  {
    name: 'Eloise AI',
    longDescription: '<p>Eloise.Ai generates top-quality written material. Craft articles, reports, blog posts, and more with unmatched efficiency. </p><p>Features</p><p>-Simplify your writing process and easily manage tasks like word count, spell check, and formatting adjustments. Simplify your progress and save time.-Enhance your writing effortlessly and refine sentences and improve clarity to create engaging content that captivates your audience.-Condense complex information into impactful introductions, study materials, or quick insights.-Create more ideas and content to create comprehensive written pieces that leave an impression.-Eloise Long-form writer helps you distill your writing to its essence. It removes unnecessary repetitions, making it impactful and concise.</p>',
    category: 'Productivity',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Eloise.Ai-Writing-Tool.jpg',
    website: 'https://eloise.ai/',
    email: 'contact@eloise.ai',
    metaDescription: 'Eloise.Ai generates top-quality written material. Craft articles, reports, blog posts, and more with unmatched efficiency.',
    description: 'Eloise.Ai generates top-quality written material. Craft articles, reports, blog posts, and more with unmatched efficiency.',
    slug: 'eloise-ai'
  },
  {
    name: 'Luna AI',
    longDescription: `<p>Get content ideas and inspiration, write incredible hooks and optimise your posts' formatting and schedule content. All in one place
</p>
<p>Save hours on writing linkedin content</p>
<p>
Export entire searches, lead lists, or projects from LinkedIn.
</p>
`,
    category: 'Productivity',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Luna-LinkedIn-Content-Creation-Tool.jpg',
    website: 'http://lunaa.io?ref=spiff.store',
    email: 'contact@lunaa.io',
    metaDescription: 'Create better LinkedIn content 10x faster with AI',
    description: 'Create better LinkedIn content 10x faster with AI',
    slug: 'luna-ai'
  },
  {
    name: 'Media.io',
    longDescription: "<p>Media.io is a fully loaded AI suite that holds almost all of the tools you need.</p><p>Features</p><p>-Massive Free Tools: We offer a wide variety of tools aimed at making creation simple but amazing.-Leading AI Tech: Our smart AI algorithm can give you pro-like results in record time, allowing you to take your work to the next level.-Rapid Iteration: We never stop improving our platform and constantly working on new AI tools to emit your creativity.-Easy to Use: Anyone can express their creativity without fear of difficulty through our user-friendly tools.-Promotional: If you're struggling to come up with a successful marketing strategy, turn your creative vision into reality with our online tool.-Educational: Media.io is designed for educators and students who want to edit video, audio, and images quickly. With advanced erasing capabilities, removing unwanted watermarks or erasing backgrounds from photos and videos is easy.</p>",
    category: 'Productivity',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/MediaIO-AI-Suite.jpg',
    website: 'https://www.media.io/?ref=spiff.store',
    email: 'contact@media.io',
    metaDescription: 'Media.io is a fully loaded AI suite that holds almost all of the tools you need.',
    description: 'Media.io is a fully loaded AI suite that holds almost all of the tools you need.',
    slug: 'media.io'
  },
  {
    name: 'AI Voice Detector',
    longDescription: '<p>AI Voice Detector is a solution for authenticating audio and protecting yourself from fake voices. With the rise of deepfake technology and voice cloning scams, it is essential to verify the authenticity of audio and video content.</p><p>Features:</p><p>-Media Verification: The tool confirms the authenticity of audio and video content, combating deepfake technology thus preventing news outlets from spreading misinformation.-Phone Scams: The AI voice detector helps detect and prevent fraudulent phone calls and scams using AI-generated voices. It ensures that the person on the other end of the phone is really who they say they are.-Content Creation: AI Voice Detector ensures that voiceovers and narrations in videos and podcasts are performed by humans.-Fake Evidence: It verifies the authenticity of audio recordings used as evidence in legal or investigative proceedings protecting individuals, businesses, and public agencies from fabricated evidence and false accusations.</p>',
    category: 'AI Detection',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/AI-Voice-Detector-tool.jpg',
    website: 'https://aivoicedetector.com/?ref=spiff.store',
    email: 'contact@aivoicedetector.com',
    metaDescription: 'AI Voice Detector is a solution for authenticating audio and protecting yourself from fake voices',
    description: 'AI Voice Detector is a solution for authenticating audio and protecting yourself from fake voices',
    slug: 'ai-voice-detector'
  },
  {
    name: 'AI Detector Pro',
    longDescription: "<p>AI Detector Pro is a handy free tool from Content At Scale that mitigates the risk of being penalized by Google for using AI-generated content. By pasting or writing your content into the detector, you'll know within seconds if it's written by AI or sounds more human. The Chat GPT detector goes deeper than a generic AI classifier to detect robotic-sounding content.</p><p>What sets Content At Scale's AI content checker apart is its high accuracy, training on billions of individual pages of data and words. It can forecast if your content is AI-generated or humanly-optimized. As AI-generated text becomes more common, this free AI checker is one of the only tools that can accurately detect word strings and text created by ChatGPT and other AI generators.</p><p>Content At Scale uses a proprietary content platform, designed by an entrepreneur with 15 years of experience in SEO and content marketing. </p><p>This AI detector is capable of analyzing and finding content generated by ChatGPT or any AI text generator. AI detection is becoming increasingly important so protect yourself and your content by knowing if it passes the bot versus human test.</p><p>Works well with Hix</p><p>Alternatives: Originality ai, Winston ai</p>",
    category: 'AI Detection',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/AI-Detector-Pro-ChatGPT-Bard-AI-Checker.jpg',
    website: 'https://contentatscale.ai/ai-content-detector/',
    email: 'contact@contentatscale.ai',
    metaDescription: 'AI Detector Pro is a handy free tool from Content At Scale that mitigates the risk of being penalized by Google for using AI-generated content',
    description: 'AI Detector Pro is a handy free tool from Content At Scale that mitigates the risk of being penalized by Google for using AI-generated content',
    slug: 'ai-detector-pro'
  },
  {
    name: 'InterviewSpark',
    longDescription: "<p>InterviewSpark is an AI Interview Coach can transform your practice sessions, receive instant feedback, and measure your progress.</p><p>InterviewSpark understands the importance of honing these skills through interview practice and offers a solution to help you stand out from the crowd.</p><p>How it works</p><p></p><p>Select Questions: Covering a diverse range of topics, these questions ensure your preparation is comprehensive and well-rounded.</p><p>Record Video: This invaluable practice helps you become comfortable with the interview process, eliminating any trace of awkwardness.</p><p>AI Feedback: The AI analyzes your responses and highlights your strengths and areas for improvement.</p><p>Track Progress: Witness your transformation into a more confident interviewee after each practice session.</p><p></p><p>Who Benefits</p><p></p><p>Job Seekers: Ace your next job interview with InterviewSpark's personalized and interactive interview preparation. </p><p>Students: From internship interviews to graduate program admissions gain confidence and expertise.</p><p>Career Changers: Whether you're transitioning to a new field or reentering the job market, this will help you enhance your interview preparation.</p>",
    category: 'Education',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/InterviewSPARK-ai-interview-coach.jpg',
    website: 'https://interviewspark.io/?ref=spiff.store',
    email: 'contact@interviewspark.io',
    metaDescription: 'InterviewSpark is an AI Interview Coach can transform your practice sessions, receive instant feedback, and measure your progress',
    description: 'InterviewSpark is an AI Interview Coach can transform your practice sessions, receive instant feedback, and measure your progress',
    slug: 'interviewspark'
  },
  {
    name: 'AnimeGenius',
    longDescription: "<p>AI Anime Art generator - simplicity, speed, and a high level of artistry</p><p>AnimeGenius converts plain text into high-quality anime art. Focusing solely on the anime niche, it offers specific features for lovers of Japanese animation as compared to generalized AI art generators that work with all kinds of texts</p><p></p><p>No model algorithms to decrypt, no intricate operations to learn. Enter a prompt word, click generate, and wait for the AI to turn your imagination into Anime art.</p><p>This is your go-to tool if you lack drawing skills or intricate knowledge of AI. Stick to straightforward prompt words and let AnimeGenius handle the rest.</p><p>Upload real photos and see them transformed into captivating anime. This technology translates your photographs into animated works of art inspired by different locales: forests and cityscapes come alive in vibrant colors, complete with stunning costumes and poses.</p><p>From 'Waifu' to 'Cyberpunk', your imagination sets the limit. From serene landscapes to steampunk worlds, Anime-AI offers a comprehensive catalog of artistic styles—all made from scratch by artificial intelligence.</p><p></p><p>Note: While AnimeGenius operates under the Creative ML Open RAIL-M License, commercial users should publish content with caution and familiarize themselves with the terms before diving in. Respect the guidelines when sharing NSFW content. Furthermore, don't forget to keep an eye on commercial use licenses.</p>",
    category: 'Generative Art',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/AnimeGenius-AI-Anime-Art-generator.jpg',
    website: 'https://animegenius.live3d.io/?fpr=aitools',
    email: 'contact@live3d.io',
    metaDescription: 'AI Anime Art generator - simplicity, speed, and a high level of artistry\n' +
      'AnimeGenius converts plain text into high-quality anime art',
    description: 'AI Anime Art generator - simplicity, speed, and a high level of artistry\n' +
      'AnimeGenius converts plain text into high-quality anime art',
    slug: 'animegenius'
  },
  {
    name: 'Pictory',
    longDescription: '<p>Pictory AI is a video creation and editing program for creating engaging videos across popular social media channels. Pictory helps you transform articles and blog posts into shareable videos, generate short videos from photographs and video clips, and add text to videos.</p><p></p><p>Use the "article to video" module lets users convert existing blog posts and articles into shareable videos by simply pasting the URL.</p><p>The "script to video" module is similar, but users paste the plain text to convert into videos, recommended for product intros and simple training guides.</p><p>Use the "visuals to video" module is for creating videos using existing images and video clips.</p><p>Try the "edit videos with voice using text" module allows users to edit videos easily by uploading a video, converting it to full transcription/subtitles, and removing words from the text that will be removed from the video as well.</p><p></p><p>Pictory also allows you to customize your clips by adding logos, intros, outros, and captions. You can pick from an array of templates and designs to guarantee that your final version is of high quality and suits your marketing goals.</p>',
    category: 'Text-to-Voice',
    imgUrl: 'https://pictory.ai/wp-content/uploads/2022/04/thumb-home.jpg',
    website: 'https://pictory.ai/',
    email: 'contact@pictory.ai',
    metaDescription: 'Pictory AI is a video creation and editing program for creating engaging videos across popular social media channels',
    description: 'Pictory AI is a video creation and editing program for creating engaging videos across popular social media channels',
    slug: 'pictory'
  },
  {
    name: 'iApply',
    longDescription: "<p>iApply is the world's first job search engine that connects job seekers with potential employers worldwide. Say goodbye to the headache of manually searching for jobs on multiple portals and websites. </p><p>Features</p><p></p><p>Realtime Jobs: iApply submits applications to up to 4,000 posted jobs around the globe in over 100 industry sectors each month. The chances of landing a job and an interview increase by applying for real-time posting jobs.</p><p>Automated Applying: iApply's AI algorithm automatically searches for relevant jobs for you, increasing the chances of finding the right job. You can start planning for the first day at work.</p><p>Worldwide Coverage: Choose up to three countries and job titles, and our AI algorithm locates and applies for matching jobs. With iApply's scanning every job recruitment site, and search engine, your job opportunities become endless.</p><p>User Dashboard: Keep a watchful eye on the jobs iApply applies for, providing detailed analytics, and details throughout the job search process.</p>",
    category: 'Productivity',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/iApply-Search-Engine-Tool.jpg',
    website: 'https://www.iapply.ai/sign-up?aid=7911d7f0-1606-4978-9da0-d9887ef6a536',
    email: 'contact@iapply.ai',
    metaDescription: "iApply is the world's first job search engine that connects job seekers with potential employers worldwide",
    description: "iApply is the world's first job search engine that connects job seekers with potential employers worldwide",
    slug: 'iapply'
  },
  {
    name: 'Aura AI',
    longDescription: `<p>[Aura AI] brings your imagination to life whether you're looking to create beautiful artwork, unique slides for presentations, or simply have some fun unleashing your creativity.</p><p>Aura AI is an app designed to transform your text prompts into stunning visual creations.</p><p>Features:</p><p>Text-To-Image: The app translates your text prompts into visually stunning images, giving you the ability to transform your ideas.</p><p>Incredible results: Just imagine starting with a simple prompt like "Two puppy dogs sitting on the couch," and then gradually transforming it into a detailed image that captures the essence of the scene.</p><p>Fun and educational: This app is perfect for transforming your ideas into reality, allowing you to craft stunning visuals effortlessly.</p><p>Work mode, fun mode, or educational mode: Whether you're in work mode, fun mode, or educational mode, Aura AI adapts to match your requirements.</p>`,
    category: 'Generative Art',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Aura-AI-Text-to-Image-tool.jpg',
    website: 'https://auraai.app?ref=spiff.store',
    email: 'contact@auraai.app',
    metaDescription: "[Aura AI] brings your imagination to life whether you're looking to create beautiful artwork, unique slides for presentations, or simply have some fun unleashing your creativity",
    description: "[Aura AI] brings your imagination to life whether you're looking to create beautiful artwork, unique slides for presentations, or simply have some fun unleashing your creativity",
    slug: 'aura-ai'
  },
  {
    name: 'Freeflo',
    longDescription: "<p>Freeflo offers a curated collection of AI image prompts that could be your creative catalyst.</p><p>Freeflo's primary focus is on style prompts. These prompts are not generic suggestions; they are carefully chosen to spark your imagination. With Freeflo, you get access to professional, reusable generative AI-style prompts.</p><p>You can use Freeflo with any image generator. Whether you use Midjourney, Stability, or Firefly—copy and paste the prompt into your preferred generator and watch it work!</p><p>Expect fresh weekly style drops to keep your creative juices flowing. Whether you're into double exposures, line drawings, or any other style, Freeflo keeps your options open.</p><p>You can use these styles for non-commercial endeavors or license them commercially. It's a versatile tool that caters to both amateurs and professionals in the creative field.</p>",
    category: 'Generative Art',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/freeflo-reusable-generative-AI-prompts.png',
    website: 'https://freeflo.ai/?ref=spiff.store',
    email: 'contact@freeflo.ai',
    metaDescription: 'Freeflo offers a curated collection of AI image prompts that could be your creative catalyst',
    description: 'Freeflo offers a curated collection of AI image prompts that could be your creative catalyst',
    slug: 'freeflo'
  }
]

// export default TOOLS;
module.exports = TOOLS;
