import React from 'react';
import Navbar from './componenets/Navbar';
import Footer from './componenets/Footer';
import PricingCards from './componenets/PricingCards';
import styles from './SubmitPage.module.css'
import FAQs from './componenets/Faqs';
import { Helmet } from 'react-helmet';

const SubmitPage = () => {

  return (
    <>
     <Helmet>
      <title>Submit Your AI Tool | Best AI Tools Directory</title>
    
    <meta property="og:title" content="Submit Your AI Tool | Best AI Tools Directory" />
    <meta property="og:description"
      content="Our submission process is simple. First select a plan that suits you and then complete the form. We will manually review your submission and if it's a fit, we will create your listing." />
    <meta property="og:image" content="%PUBLIC_URL%/hero.png" />
    <meta property="og:type" content="website" />
    
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Submit Your AI Tool | Best AI Tools Directory" />
    <meta name="twitter:description"
      content="Our submission process is simple. First select a plan that suits you and then complete the form. We will manually review your submission and if it's a fit, we will create your listing." />
    <meta name="twitter:image" content="%PUBLIC_URL%/hero.png" />

    
    <meta name="description" content="Our submission process is simple. First select a plan that suits you and then complete the form. We will manually review your submission and if it's a fit, we will create your listing." />
    </Helmet>
      <Navbar />
      
      <div className={styles.submitContainer}>
        <h1>Submit Your AI Tool</h1>

        <p style={{fontSize: '18px'}}>Our submission process is simple. First select a plan that suits you and then complete the form. We will manually review your submission and if it's a fit, we will create your listing.</p>

        <PricingCards />

        <FAQs />

      </div>
      <Footer />
    </>
  );
};

export default SubmitPage;
