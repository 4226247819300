import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Pagination.module.css';
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';

const Pagination = ({ currentPage, totalPages, onPageClick, onNextPage, onPrevPage }) => {
  const location = useLocation();

  useEffect(() => {
    // Extract page number from URL and set it as currentPage when the component mounts
    const pageNumber = parseInt(location.pathname.split('-').pop(), 10);
    if (!isNaN(pageNumber)) {
      onPageClick(pageNumber);
    }
  }, [location.pathname, onPageClick]);

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 6;
    let startPage, endPage;

    if (totalPages <= maxPagesToShow) {
      // Less than or equal to 6 pages, show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // More than 6 pages, calculate start and end pages
      if (currentPage <= 4) {
        startPage = 1;
        endPage = maxPagesToShow;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - maxPagesToShow + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 3;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const renderPaginationButtons = () => {
    const pageNumbers = getPageNumbers();

    return (
      <>
        {currentPage !== 1 && (
          <Link to="/" className={styles['pagination-button']} onClick={() => onPageClick(1)}>
            <FiChevronsLeft />
          </Link>
        )}
        {currentPage > 1 && (
          <Link
            to={`/ai-tools/page-${currentPage - 1}`}
            className={styles['pagination-button']}
            onClick={onPrevPage}
          >
            <FiChevronLeft />
          </Link>
        )}
        {pageNumbers.map((page) => (
          <Link
            key={page}
            to={page === 1 ? '/' : `/ai-tools/page-${page}`}
            className={`${styles['pagination-button']} ${
              currentPage === page ? styles['active'] : ''
            }`}
            onClick={() => onPageClick(page)}
          >
            {page}
          </Link>
        ))}
        {currentPage < totalPages && (
          <Link
            to={`/ai-tools/page-${currentPage + 1}`}
            className={styles['pagination-button']}
            onClick={onNextPage}
          >
            <FiChevronRight />
          </Link>
        )}
        {currentPage !== totalPages && (
          <Link to={`/ai-tools/page-${totalPages}`} className={styles['pagination-button']} onClick={() => onPageClick(totalPages)}>
            <FiChevronsRight />
          </Link>
        )}
      </>
    );
  };

  return <div className={styles['pagination-container']}>{renderPaginationButtons()}</div>;
};

export default Pagination;
