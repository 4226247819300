const TOOLS = [
  {
    name: 'Ecommerce Prompts',
    longDescription: '<p></p>',
    category: 'Copywriting',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Ecommerce-Prompts-AI-tools.jpeg',
    website: 'https://www.ecommerceprompts.com/?ref=spiff.store',
    email: 'contact@ecommerceprompts.com',
    metaDescription: '',
    description: '',
    slug: 'ecommerce-prompts'
  },
  {
    name: 'Hotreach AI',
    longDescription: '<p></p>',
    category: 'Marketing',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Hotreach-ai.jpeg',
    website: 'https://www.hotreachai.com/?ref=spiff.store',
    email: 'contact@hotreachai.com',
    metaDescription: '',
    description: '',
    slug: 'hotreach-ai'
  },
  {
    name: 'DeepL',
    longDescription: '<p></p>',
    category: 'Translation',
    imgUrl: 'https://storage.googleapis.com/hexomatic-screenshot/60ead9f07c491200103fb892/633846f27dd6e800101a60ca/crop-image/1665661788924-https---www.deepl.com-translator-Laptop Small-1665661772985.jpeg',
    website: 'https://www.deepl.com/translator?ref=spiff.store',
    email: 'contact@deepl.com',
    metaDescription: '',
    description: '',
    slug: 'deepl'
  },
  {
    name: 'Nanonets',
    longDescription: '<p></p>',
    category: 'Productivity',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/nanonets-OCR-automation-for-business.jpeg',
    website: 'https://nanonets.com/?ref=spiff.store',
    email: 'contact@nanonets.com',
    metaDescription: '',
    description: '',
    slug: 'nanonets'
  },
  {
    name: 'Inworld',
    longDescription: '<p></p>',
    category: 'Gaming',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Inworld-AI.jpeg',
    website: 'https://inworld.ai/?ref=spiff.store',
    email: 'contact@inworld.ai',
    metaDescription: '',
    description: '',
    slug: 'inworld'
  },
  {
    name: 'Visily',
    longDescription: '<p></p>',
    category: 'Image Editing',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/visily-app-wireframes.jpeg',
    website: 'https://www.visily.ai/?ref=spiff.store',
    email: 'contact@visily.ai',
    metaDescription: '',
    description: '',
    slug: 'visily'
  },
  {
    name: 'Automata',
    longDescription: '<p></p>',
    category: 'Copywriting',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Automata.jpeg',
    website: 'https://byautomata.io/?ref=spiff.store',
    email: 'contact@byautomata.io',
    metaDescription: '',
    description: '',
    slug: 'automata'
  },
  {
    name: 'Reflect',
    longDescription: '<p></p>',
    category: 'Productivity',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Reflect-AI-notetaking.jpeg',
    website: 'https://reflect.app/?ref=spiff.store',
    email: 'contact@reflect.app',
    metaDescription: '',
    description: '',
    slug: 'reflect'
  },
  {
    name: 'Fathom',
    longDescription: '<p></p>',
    category: 'Podcasting',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Fathom-AI-podcast-player.jpeg',
    website: 'https://hello.fathom.fm/?ref=spiff.store',
    email: 'contact@fathom.fm',
    metaDescription: '',
    description: '',
    slug: 'fathom'
  },
  {
    name: 'Alfie',
    longDescription: '<p></p>',
    category: 'Copywriting',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Alfie-AI-editor-for-content-marketers.jpg',
    website: 'http://tryalfie.com/?ref=spiff.store',
    email: 'contact@tryalfie.com',
    metaDescription: '',
    description: '',
    slug: 'alfie'
  },
  {
    name: 'Piggy',
    longDescription: '<p></p>',
    category: 'Generative Art',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Piggy-content-creator.jpg',
    website: 'https://piggy.to/magic?ref=spiff.store',
    email: 'contact@piggy.to',
    metaDescription: '',
    description: '',
    slug: 'piggy'
  },
  {
    name: 'Endless VN',
    longDescription: "<p>Endless Visual Novel is an AI storytelling game where all assets — graphics, music, story and characters — are generated by AI as you play. No two playthroughs will ever be the same.</p><p>It's a game that lets you generate whole new worlds from a short prompt. You can customize characters and locations and even write your own dialogue; then play it and share it with others!</p><p>Alternatives: Artflow AI</p>",
    category: 'Generative Art',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Endless-Visual-Novel-AI-book-writing-tool.jpeg',
    website: 'https://endlessvn.io/?ref=spiff.store',
    email: 'contact@endlessvn.io',
    metaDescription: 'Endless Visual Novel is an AI storytelling game where all assets — graphics, music, story and characters — are generated by AI as you play',
    description: 'Endless Visual Novel is an AI storytelling game where all assets — graphics, music, story and characters — are generated by AI as you play',
    slug: 'endless-vn'
  },
  {
    name: 'Flowrite',
    longDescription: "<p>Flowrite is an AI email writer that creates unique messages for you, based on your instructions and your recipient's profile. No more copy-pasting the same thing over and over again—just give Flowrite a few short sentences, watch it create a message based on those instructions, and send. You'll be done in no time!</p><p>You can also use Flowrite to create smart templates for emails that you write often. That way you can spend less time writing them, and more time tinkering with other productivity tools (yep, I do it too).</p><p>So if you're looking for something better than what your current email system has to offer—something that will save you time while still getting the results you need.</p><p>Alternatives: Email Magic</p>",
    category: 'Copywriting',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Flowrite-AI-email-inbox-marketing.jpg',
    website: 'https://www.flowrite.com/?ref=spiff.store',
    email: 'contact@flowrite.com',
    metaDescription: "Flowrite is an AI email writer that creates unique messages for you, based on your instructions and your recipient's profile",
    description: "Flowrite is an AI email writer that creates unique messages for you, based on your instructions and your recipient's profile",
    slug: 'flowrite'
  },
  {
    name: 'TweetMonk',
    longDescription: "<p>AI-powered Twitter editor that helps users write better tweets, grow their audience, and improve their Twitter presence. It offers an easy-to-use editor, AI-powered writing tools, powerful analytics, and daily inspiration prompts to help users overcome writer's block. Additionally, it provides features like auto-split text in tweets, thread finisher, tweet numbering, multi-account publishing, poll integration, and multimedia support. Trusted by over 1500 creators and businesses, Tweetmonk offers a free trial and integrates with multiple social platforms. Its performance analytics and engagement tracking help users discover patterns and opportunities to better connect with their audience.</p><p>Alternatives: Tweethunter, HypeFury</p>",
    category: 'Social Media',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Tweetmonk-Twitter-AI-tool.jpeg',
    website: 'https://tweetmonk.com/?ref=spiff.store',
    email: 'contact@tweetmonk.com',
    metaDescription: 'AI-powered Twitter editor that helps users write better tweets, grow their audience, and improve their Twitter presence',
    description: 'AI-powered Twitter editor that helps users write better tweets, grow their audience, and improve their Twitter presence',
    slug: 'tweetmonk'
  },
  {
    name: 'Midjourney Prompt Generator',
    longDescription: '<p></p>',
    category: 'Generative Art',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/image-generation-prompt-creator.jpeg',
    website: 'https://www.howtoleverageai.com/midjourney-prompt-generator?ref=spiff.store',
    email: 'contact@howtoleverageai.com',
    metaDescription: '',
    description: '',
    slug: 'midjourney-prompt-generator'
  },
  {
    name: 'AIPRM',
    longDescription: '<p></p>',
    category: 'SEO',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/AIPRM-ChatGPT-prompts.jpeg',
    website: 'https://www.aiprm.com/?ref=spiff.store',
    email: 'contact@aiprm.com',
    metaDescription: '',
    description: '',
    slug: 'aiprm'
  },
  {
    name: 'Compose AI',
    longDescription: "<p>Compose AI is a free Chrome extension that uses AI-powered autocompletion and text generation to help users save time and automate their writing. It can be used to generate ideas for stories, blog posts, research topics, and more. The tool can also autocomplete sentences and suggest new ways to say things. It seamlessly integrates with existing platforms and can be used to compose emails and generate professional replies. Compose AI is personalized to the user's voice and writing style and learns over time. The plugin is free to use forever, and there is a premium version with some advanced features.</p>",
    category: 'Copywriting',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Compose-AI-content-in-Google-docs-and-gmail.jpeg',
    website: 'https://www.compose.ai?ref=spiff.store',
    email: 'contact@compose.ai',
    metaDescription: 'Compose AI is a free Chrome extension that uses AI-powered autocompletion and text generation to help users save time and automate their writing',
    description: 'Compose AI is a free Chrome extension that uses AI-powered autocompletion and text generation to help users save time and automate their writing',
    slug: 'compose-ai'
  },
  {
    name: 'Runway ML',
    longDescription: '<p></p>',
    category: 'Generative Video',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Runway-AI-video.jpeg',
    website: 'https://research.runwayml.com/gen1?ref=spiff.store',
    email: 'contact@runwayml.com',
    metaDescription: '',
    description: '',
    slug: 'runway-ml'
  },
  {
    name: 'PromptLayer',
    longDescription: '<p></p>',
    category: 'Copywriting',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/PromptLayer-prompt-engineering.jpeg',
    website: 'https://promptlayer.com/?ref=spiff.store',
    email: 'contact@promptlayer.com',
    metaDescription: '',
    description: '',
    slug: 'promptlayer'
  }
]

// export default TOOLS;
module.exports = TOOLS;
