import React from "react"
// import TOOLS from "../data/Tools"
import Tool from "./Tool"


const ToolsList = ({TOOLS, prefixUrl}) => {
  console.log('ToolsList', prefixUrl)
  return (
    <div className="container">
      {TOOLS.map(tool => (
        <React.Fragment key={tool?.slug}>
          <Tool tool={tool} prefixUrl={prefixUrl} />
        </React.Fragment>
      ))}
    </div>
  )
}

export default ToolsList