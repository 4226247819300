import React from 'react';
import { FaStar } from 'react-icons/fa';
import styles from './ReviewList.module.css';

const CustomReview = ({ review }) => {
  return (
    <div className={styles.reviewContainer}>
      <div className={styles.header}>
        <h3 className={styles.authorName}>{review.author}</h3>
        <div className={styles.starRating}>
          {[...Array(5)].map((_, index) => (
            <FaStar 
              key={index} 
              className={index < review.rating ? styles.starFilled : styles.starEmpty} 
            />
          ))}
        </div>
      </div>
      <p className={styles.companyName}>{review.company}</p>
      <p className={styles.reviewText}>{review.text}</p>
      <h4 className={styles.headline}>
        <span className={styles.highlightedText}>{review.highlightedText}</span> {review.headlineText}
      </h4>
    </div>
  );
};

const ReviewList = ({ reviews }) => {
  return (
    <div className={styles.reviewList}>
      {reviews.map((review, index) => (
        <CustomReview key={index} review={review} />
      ))}
    </div>
  );
};

export default ReviewList;