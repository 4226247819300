const META = [{
  title: 'Top 24 Artificial Intelligence (AI) Tools List - Spiff.store',
  desc: 'This page covers list of top AI tools. Discover Aritificial Intelligence tools'
}, 
{
  title: 'The best AI tools in ' + new Date().getFullYear(),
  desc: 'These 24 AI apps will help you work faster and better'
},
{
  title: 'Find The Best AI Tools & Websites',
  desc: 'Spiff.store is a free site to help you discover the best AI tools and software to make your work and life more efficient and productive'
},
{
  title: 'The Ultimate List of Popular AI Tools | ' + new Date().getFullYear(),
  desc: 'Spiff.store is a free site to help you discover the best AI tools and software to make your work and life more efficient and productive'
},
{
  title: 'Complete AI List',
  desc: 'AI can always help you with whatever you do'
},
{
  title: 'List of AI Tools - Spiff.store',
  desc: 'Discover AI Tools for Your Business.'
},
{
  title: 'Best AI Tools',
  desc: 'For Work, Creativity & Productivity'
},

{
  title: '400+ Best AI Tools - Full AI Tools Directory (' + new Date().getFullYear() + ')',
  desc: 'Find the best AI tools; Compare 400+ AI Tools across 10+ Categories. Learn about top AI tools, AI Chatbots, ChatGPT plugins, How to use AI.'
},

{
  title: '24 AI tools you can try for free',
  desc: 'Whether you need to search, summarize, write, transcribe, or generate art or music, there\'s a free AI tool here to help you.'
},

{
  title: 'Top AI Tools Directory',
  desc: 'The world\'s best expert curated list of AI Tools'
},
{
  title: 'AI Tools Directory & List of Best Free AI by Category (Top 24)',
  desc: 'The top list of newest AI tools available on the web'
},
{
  title: 'Top 24 Artificial Intelligence (AI) Tools You Need to Know',
  desc: 'The most popular generative AI tools. Discover the ultimate Top AI Tools Directory.'
},
{
  title: 'Best Generative AI Tools: Boost Your Productivity',
  desc: 'Find out which Artificial Intelligence tools you can add to your toolbox to simplify your work'
},
{
  title: 'Top 24 Free AI Tools for ' + new Date().getFullYear() + ' You Don\'t Want to Miss',
  desc: 'Discover the latest and most powerful free AI tools available in ' + new Date().getFullYear()
},
{
  title: '24 Mind-Blowing AI Tools Everyone',
  desc: 'The always-up-to-date list of best AI tools.'
},
]

export default META
