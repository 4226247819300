import React from 'react';
import './FAQs.css'; // Importing CSS file for styling

const FAQs = () => {
  const faqs = [
    {
      question: "What tools are not allowed?",
      answer: "Any website that displays sexual or violent content."
    },
    {
      question: "How do I get featured faster?",
      answer: "With the fastlane plan you get listed in under business 2 days."
    },
    {
      question: "Can I list for free?",
      answer: "Yes. You can list with the free plan. The waiting time for free listings is 2 months."
    },
  ];

  return (
    <div className="faq-section">
      <h2 className="faq-heading">Frequently Asked Questions</h2>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq">
            <h3 className="faq-question">{faq.question}</h3>
            <p className="faq-answer">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQs;
