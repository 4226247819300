import './App.css';
import Hero from './componenets/Hero';
import ToolsList from './componenets/ToolsList';
import Navbar from './componenets/Navbar';
import Footer from './componenets/Footer';
import { Helmet } from 'react-helmet';
// import TOOLS from './data/ai-tools/page-1';
import React, { useState } from 'react';
import Pagination from './componenets/Pagination';
import SearchComponent from './componenets/SearchComponent';

function App({ TOOLS, META }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  // console.log('app', TOOLS)
  const [currentPage, setCurrentPage] = useState(1); // Initial current page
  const totalPages = 16; // Assuming 18 total pages

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchSubmit = filteredResults => {
    setFilteredResults(filteredResults);
    setCurrentPage(1); // Reset to the first page when a new search is performed
  };


  const clearSearch = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setFilteredResults([])
  }
  return (
    <div className="App">
      <Helmet>
        <title>{META?.title}</title>
    
        <meta property="og:title" content={META?.title} />
        <meta property="og:description"
          content={META?.desc} />
        <meta property="og:image" content="%PUBLIC_URL%/hero.png" />
        <meta property="og:type" content="website" />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={META?.title} />
        <meta name="twitter:description"
          content={META?.desc} />
        <meta name="twitter:image" content="%PUBLIC_URL%/hero.png" />
        
        <meta name="description" content={META?.desc} />
        <meta name="keywords" content="tools, ai, chatgpt, list, directory,database,backlinks" />
      </Helmet>
      <Navbar />
      <SearchComponent onSubmit={handleSearchSubmit} setSearchTerm={setSearchTerm} />
      
      {filteredResults?.length ?
        <Hero META={{title: `Search reuslts for "${searchTerm}"`, desc: <button onClick={clearSearch} className='clear-search-btn'>Clear Search</button>}} />
        // <div>
        //   <h1>Search reuslts for "{searchTerm}"</h1>
        //   <button onClick={() => setFilteredResults([])}>Clear Search</button>
        // </div>
      :
        <Hero META={META} />
      }


      <ToolsList TOOLS={filteredResults?.length ? filteredResults :  TOOLS} prefixUrl='/tool' />

      {
        filteredResults.length ? 
        ""
        : 
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageClick={handlePageClick}
        onNextPage={handleNextPage}
        onPrevPage={handlePrevPage}
      />
      }


    {/* <footer>
      <p>© {new Date().getFullYear()} Spiff.store</p>
    </footer> */}

    <Footer />
    </div>
  );
}

export default App;
