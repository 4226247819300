const TOOLS = [
  {
    name: 'Profile Pic Maker',
    longDescription: "<p>If you're looking for a way to add flair to your profile picture for social media and your website, Profile Picture Maker is worth a look. Upload a few of your finest photos (in profile mode) and get back hundreds of images that represent you (or the essence of your face) in various styles and settings. </p><p>Whether you want a classic portrait, a modern or futuristic look, PFPMaker has a generated image for you.</p><p>Ideal for professionals, freelancers, and anyone looking to up their social media game. Don't settle for a mediocre profile picture. Get some unique style down on and stand out with a dash of creativity and a pinch of personal style.</p><p>Alternatives: Secta Labs, Profile Picture AI, ProPhotos</p>",
    category: 'Generative Art',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Profile-Pic-Maker-AI-professional-profile-tool.jpg',
    website: 'https://pfpmaker.com/?ref=spiff.store',
    email: 'contact@pfpmaker.com',
    metaDescription: "If you're looking for a way to add flair to your profile picture for social media and your website, Profile Picture Maker is worth a look",
    description: "If you're looking for a way to add flair to your profile picture for social media and your website, Profile Picture Maker is worth a look",
    slug: 'profile-pic-maker'
  },
  {
    name: 'SUPERMACHINE',
    longDescription: "<p>SUPERMACHINE lets you generate images in under 15 seconds and in a variety of aspect ratios. </p><p></p><p>For bloggers—whip up unique graphics for your posts</p><p>For content creators—make your own original art for social media, ads, promo materials, and guides.</p><p>For copywriters—generate eye-catching imagery to complement your words</p><p></p><p>Key Features:</p><p></p><p>Got an image in your mind? Translate it into words and watch as SUPERMACHINE breathes life into it. Once generated, you can fine-tune your output via an edit canvas to achieve the perfect image. Every image generated by SUPERMACHINE is an original. Better yet, they come with commercial rights enabling you to use them just as you please.</p><p>40 custom models give you an incredible range of possibilities. All you have to do is tell the AI what kind of image you're looking for, and voilà! It delivers—with easy editing options too!</p><p>Increase the size of faces in your photos fourfold by adjusting their scale.</p><p>Use the Advanced Styling menu to select from an array of styles, themes, and genres. Choose from dozens of artistic options that let you blend different looks together into your images with ease.</p><p>Quickly find inspiration from other users' works. Use prompts that have inspired you to create new images—then easily produce an image with the same feel.</p><p>Use the built-in ESRGAN tool to remold AI-generated faces that look odd.</p>",
    category: 'Generative Art',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/SUPERMACHINE-AI-image-generation-tool.jpg',
    website: 'https://supermachine.art/?ref=aitools-qgxc8qbhn0068vt',
    email: 'contact@supermachine.art',
    metaDescription: 'SUPERMACHINE lets you generate images in under 15 seconds and in a variety of aspect ratios',
    description: 'SUPERMACHINE lets you generate images in under 15 seconds and in a variety of aspect ratios',
    slug: 'supermachine'
  },
  {
    name: 'Chaindesk',
    longDescription: "<p>Chaindesk AI is a nocode custom AI chatbot solution to help your business improve customer support, internal communication, onboarding, and more. What makes it unique is that each custom chatbot's capabilities and responses are influenced and shaped by your unique data sets. This creates an AI solution that knows your needs as well as you do.</p><p></p><p>Load data from any source and have intelligent conversations about your documents. Use ChatGPT to talk with your data and have conversations with your business files. </p><p>Ask questions, extract information, and summarize documents with AI. Chat in multiple languages</p><p>Every response is supported by references to sources found in the uploaded document.</p><p>An AI chatbot can answer frequently asked questions and handle simple support requests, freeing up your team's time to provide more personalized assistance. Your business can deliver around-the-clock custom, personalized customer support </p><p>This is a nocode solution - integrate your Chaindesk chatbot by copying and pasting a line of code into your site and start providing instant support to users. Build and manage AI chatbots without incurring any software development costs or hiring programmers.</p><p>Use built-in functionality and modular components to create intelligent agents that connect to data sources, allowing limitless applications. Integrate your AI chatbot with your communications channels, such as Slack, Whatsapp, and platforms where your customers prefer to communicate.</p>",
    category: 'Chat',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Databerry-chatGPT-agent-your-data.jpeg',
    website: 'https://www.chaindesk.ai/',
    email: 'contact@chaindesk.ai',
    metaDescription: 'Chaindesk AI is a nocode custom AI chatbot solution to help your business improve customer support, internal communication, onboarding, and more',
    description: 'Chaindesk AI is a nocode custom AI chatbot solution to help your business improve customer support, internal communication, onboarding, and more',
    slug: 'chaindesk'
  },
  {
    name: 'tl;dv',
    longDescription: "<p>Improve your meetings and enhance collaboration with tl;dv's automated recording, transcription, and summarization features. tl;dv might be the AI-powered meeting sidekick you never knew you needed. </p><p>Capture and document important points from every meeting.</p><p></p><p>Recording and transcribed your calls without worrying about technical aspects. You can hand that over to artificial intelligence software. Capture and store high-quality video and audio. Timestamp and summarize key moments in your meetings with just a click or shortcut.</p><p>tl;dv does timestamping of key moments in meetings and creates bite-sized videos for sharing. Additionally, easy keyword search functionality and seamless integration with popular collaboration tools allow for increased flexibility and productivity during meetings.</p><p>Use the video clipper to create bite-sized clips from longer meetings. Support for 20+ languages, including German, English, French, Spanish, Japanese, and more. You won't miss any details from your customer calls. TL;DV use speaker detection technology, searching for specific spoken words in your meetings.</p><p>Connect to your CRM or productivity tools like Notion, Google Docs, Slack, Salesforce, and HubSpot.</p>",
    category: 'Productivity',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/tldv-Ai-meeting-notes-tool.jpg',
    website: 'https://tldv.cello.so/AmqTDTq5Inc',
    email: 'contact@cello.so',
    metaDescription: "Improve your meetings and enhance collaboration with tl;dv's automated recording, transcription, and summarization features",
    description: "Improve your meetings and enhance collaboration with tl;dv's automated recording, transcription, and summarization features",
    slug: 'tl;dv'
  }
]

// export default TOOLS;
module.exports = TOOLS;
