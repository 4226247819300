import { Link } from "react-router-dom"
import LazyLoad from 'react-lazyload';

const Tool = ({tool, prefixUrl}) => {
  console.log('Tool', prefixUrl)

  return (
    <Link to={`${prefixUrl}/${tool?.slug}`} className="card">

      {typeof window !== 'undefined' ? (
        <LazyLoad height={200} offset={100}>
          <img className="card-image" src={tool?.imgUrl} alt={tool?.name} />
        </LazyLoad>
      ) : (
        <img className="card-image" src={tool?.imgUrl} alt={tool?.name} />
      )}      
      <div className="card-content">
        {
          tool?.name ? '' :
          <p className="badge">{tool?.category}</p>
        }
        <h2>{tool?.name ? tool?.name : tool?.title}</h2>
        {
          tool?.name ?
          <p className="badge">{tool?.category}</p>
          :
          ''
        }
        <p>{tool?.description?.length > 150 ? tool?.description.slice(0,140) + '...' : tool?.description}</p>
      </div>
    </Link>
  )
}

export default Tool
