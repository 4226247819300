import Hero from "./componenets/Hero";
import ToolsList from "./componenets/ToolsList";
import './App.css';
import Navbar from "./componenets/Navbar";
import Footer from "./componenets/Footer";

const BlogsPage = ({BLOGS}) => {
  const META = {title: 'AI Toolbox: Unveiling the Best Tools for Every Use Case', desc: 'Discover the ultimate AI arsenal tailored for diverse needs. Our AI blog delves into recommending top-tier tools curated for specific use cases, empowering you to harness the full potential of artificial intelligence in your endeavors'}
  return (
    <div className="blogs-page">
      <Navbar />
      <Hero META={META} />
      <ToolsList TOOLS={BLOGS} prefixUrl='/ai-blog' />
      <Footer />
    </div>
  );

}

export default BlogsPage
