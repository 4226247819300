const TOOLS = [
  {
    name: 'Gummysearch',
    longDescription: "<p>Market your business or product better on Reddit (one of the world's largest websites). Discover breakout niches by exploring the top active/growing communities on Reddit. Use GummySearch's AI pattern finder to analyze Reddit posts & comments in bulk to uncover trends that matter to your business. This is useful to quickly analyze many pain points or solution requests to find common ones, or generally understand the buzz happening in niche communities on Reddit. </p><p>The pattern finder reads significantly faster than humans do, turning hours of reading & understanding Reddit posts into the click of a button.</p><p>Peek into conversations by accessing no-filter Reddit threads that are organized by category and audience type (all time / past month). GummySearch surfaces the most important threads so you don't have to read through pages and pages of history just to find what matters most right now!</p>",
    category: 'Social Media',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Gummysearch-AI-marketing-on-Reddit.jpg',
    website: 'https://gummysearch.com/',
    email: 'contact@gummysearch.com',
    metaDescription: "Market your business or product better on Reddit (one of the world's largest websites)",
    description: "Market your business or product better on Reddit (one of the world's largest websites)",
    slug: 'gummysearch'
  },
  {
    name: 'Lately',
    longDescription: `
    <p>Unlock the Power of Neuroscience-Driven AI.</p>
    <p>Welcome to Lately - where your brands' social voice creates connection with your audience.
</p>
    <p>Customers buy from local markets and brands that don't adapt sound out of touch and tone deaf. Our platform listens, learns, and adapts to your unique brand tones and voices, ensuring consistency and authenticity in every post. As you work with Lately, it learns what resonates with the distinct audiences of your brand, sub-brands and even employees, and how to tell each of their stories. We dive deep into your brand's essence to create a rich, compelling social media calendar filled with posts that not only engage but turn your audience into devoted fans and brand advocates.</p>
    <p>Harness the power of Generative AI with Lately. We provide a scalable solution that respects the nuances of brand safety, ensuring that every piece of content reinforces your brand identity while captivating your audience.</p>
    <p>Join the ranks of social marketing teams who no longer fear the daily grind of content creation. With Lately, you're not just keeping up; your team is setting the pace. Create content that matters, day after day, and watch as your brand's audience grows from mere followers into true brand advocates.</p>
    <p>Scalable Content Strategy: No matter the size of your organization, Lately scales with your team and brands, making high-quality social media marketing accessible to all. Lately makes your team and everyone in your brand brilliant social marketers every day. Week after week, month after month.</p>
    `,
    category: 'Copywriting',
    imgUrl: 'https://assets-global.website-files.com/5fd3c52ce5bc147a1f007ca4/64bab93ce02c5966f7655ec0_lately_hdr_meta.png',
    website: 'https://www.lately.ai/?ref=spiff.store',
    email: 'contact@lately.ai',
    metaDescription: 'Unlock the Power of Neuroscience-Driven AI.',
    description: 'Unlock the Power of Neuroscience-Driven AI.',
    slug: 'lately'
  },
  {
    name: 'WriteText.ai for Magento',
    longDescription: `<p>WriteText.ai is an AI-driven product description generator designed for Magento. This extension seamlessly produces meta titles, meta descriptions, both comprehensive and brief product descriptions, Open Graph texts, and image alt texts directly from your Magento backend.</p>
    <h2>Features:</h2>
    <ul>
        <li>No limits on the number of ecommerce sites and user accounts.</li>
        <li>Effortless direct publishing and data transfer to Magento.</li>
        <li>Options to select specific product attributes, tones, and styles.</li>
        <li>Adjustable content length, target audience, and user role settings.</li>
        <li>Enhanced text generation through advanced image analysis by WriteText.ai, retrieving additional details from product images to craft precise and compelling descriptions, especially beneficial for items with scant data or intricate names.</li>
        <li>AI-driven recommendations for optimal target markets.</li>
        <li>Detailed keyword and semantic keyword analysis.</li>
        <li>Monitoring and optimization of keyword density for SEO purposes.</li>
        <li>Capabilities for text rewriting.</li>
        <li>Product referencing to ensure alignment with the tone, style, and layout of existing products.</li>
        <li>A comprehensive log of content review history and management options for bulk content.</li>
        <li>Support for managing multiple stores.</li>
        <li>A Chrome extension for streamlined content management and product tagging, ideal for fact-checking or adjusting content.</li>
    </ul>
    <p>WriteText.ai is available in two modes of operation: 'WriteText.ai Single' and 'WriteText.ai Bulk.' The former allows for precise customization of settings for individual products before text generation, while the latter enables automated text generation for multiple products simultaneously without manual intervention.</p>
    <p>This tool seamlessly integrates with an unlimited number of ecommerce platforms and enables the creation of multiple user accounts at no additional cost.</p>
    <p>WriteText.ai Single grants users the ability to specify the tone, style, and target market for a particular product, conduct thorough keyword analysis, and choose semantic keywords as well as certain product attributes to be included in the generated text. WriteText.ai crafts bespoke text in roughly 50 seconds, after which users can refine and finalize the content prior to publishing. This setting is designed to produce highly tailored content that addresses specific audience requirements, with a strong focus on SEO.</p>
    <p>On the other hand, WriteText.ai Bulk is ideal for generating content for many products at once. It allows users to select a group of products and apply a consistent tone, style, and attributes across all selected items. Optimized for speed and efficiency, this mode accelerates the content creation process, meeting the demands of large-scale operations.</p>
    <p>To cater to a global audience, Version 1.2 of WriteText.ai supports multiple languages, including Danish, Swedish, Norwegian, German, French, Portuguese, Spanish, Catalan, Dutch, and Italian. This functionality allows businesses to automatically generate content directly in the primary language of their Magento site, thereby broadening their market reach and enhancing international customer engagement.</p>
    <p>Moreover, WriteText.ai intelligently adjusts its output based on the language settings of your site. In situations where the desired language is unavailable, the system defaults to English. Continuous efforts are being made to expand interface translation to accommodate additional languages, improving accessibility for users worldwide.</p>
    <p>#WriteYourTextWithAI</p>
    <p><strong>Disclaimer:</strong> WriteText.ai is an independent tool developed to work with Magento. It is not affiliated or endorsed by Magento.</p>
</body>`,
    category: 'Copywriting',
    imgUrl: 'https://writetext.ai/hubfs/Magento%20promotional%20images/img_main_1600x1000px.jpg',
    website: 'https://writetext.ai/?ref=spiff.store',
    email: 'salesportals@writetext.ai',
    metaDescription: 'Enhance your Magento store with WriteText.ai, the AI-powered tool for generating dynamic product descriptions effortlessly.',
    description: 'WriteText.ai is an AI-driven tool tailored for generating product descriptions within Magento.',
    slug: 'write-text-ai-for-magento',
  },
  {
    name: 'Tabnine',
    longDescription: '<p></p>',
    category: 'Coding',
    imgUrl: 'https://storage.googleapis.com/hexomatic-screenshot/60ead9f07c491200103fb892/633846f27dd6e800101a60ca/crop-image/1669821578069-https---www.tabnine.com--LaptopSmall-1669821577495.jpeg',
    website: 'https://www.tabnine.com/?ref=spiff.store',
    email: 'contact@tabnine.com',
    metaDescription: '',
    description: '',
    slug: 'tabnine'
  },
  {
    name: 'Adobe Sensei',
    longDescription: '<p></p>',
    category: 'Image Editing',
    imgUrl: 'https://p190.p3.n0.cdn.getcloudapp.com/items/Z4umJg4g/802c73e8-b9e7-4838-afa3-8f402ce3cbcd.png?source=viewer&v=7623b6ca29d2f91be870686a03f55de1.jpg',
    website: 'https://www.adobe.com/sensei.html?clickref=1101lywmLxRG&mv=affiliate&mv2=pz&as_camptype=&as_channel=affiliate&as_source=partnerize&as_campaign=ffm',
    email: 'contact@adobe.com',
    metaDescription: '',
    description: '',
    slug: 'adobe-sensei'
  },
  {
    name: 'Wavel AI',
    longDescription: "<p>Wavel AI offers a comprehensive solution for creating voiceovers, dubbing, and subtitles in over 20 languages. Perfect for marketers, writers, and entrepreneurs, it helps increase viewer reach by overcoming language barriers and enabling content accessibility worldwide. With an AI-powered voice generator, realistic human-like voices can be added to videos, complete with emotions, pitch, and precise synchronization. Multilingual dubbing is also made simple, allowing for content to have a truly global impact. Besides its rich features and language support, Wavel AI's platform seamlessly integrates with multiple platforms, including YouTube and Vimeo, making video distribution easy and efficient.</p>",
    category: 'Text-to-Voice',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Wavel-AI-assistant-for-video.jpeg',
    website: 'https://wavel.ai/?ref=spiff.store',
    email: 'contact@wavel.ai',
    metaDescription: 'Wavel AI offers a comprehensive solution for creating voiceovers, dubbing, and subtitles in over 20 languages',
    description: 'Wavel AI offers a comprehensive solution for creating voiceovers, dubbing, and subtitles in over 20 languages',
    slug: 'wavel-ai'
  },
  {
    name: 'NovelAI',
    longDescription: "<p>Novel AI is a monthly subscription service for AI-assisted authorship, storytelling, and virtual companionship. It's a GPT-powered sandbox for your imagination. It's a place where you can expand your writing potential and explore new frontiers. It's the future of writing, and it's here to stay.</p><p>The AI algorithms create human-like writing based on your own style and perspective, so it feels like you have a writing partner or personal writing coach who never gets tired, never needs a break, and never judges you for your crazy ideas.</p><p>Import your old stories from AIDCAT or AIDWI and continue writing them in this magical world so you can expand your story's potential.</p><p>With its proprietary Natural Language Processing (NLP) platform, NovelAI allows you to access a GPT-powered sandbox where you can let your imagination run wild.</p>",
    category: 'Generative Text',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Novel-AI-book-writing-tool.jpg',
    website: 'https://novelai.net/?ref=spiff.store',
    email: 'contact@novelai.net',
    metaDescription: 'Novel AI is a monthly subscription service for AI-assisted authorship, storytelling, and virtual companionship',
    description: 'Novel AI is a monthly subscription service for AI-assisted authorship, storytelling, and virtual companionship',
    slug: 'novelai'
  },
  {
    name: 'Leadpages AI Engine',
    longDescription: `<p>A new Leadpages feature set called AI Engine gives you access to time-saving AI technology inside the Drag & Drop Builder. Generate new headlines with just a few clicks, get rid of writer's block, brainstorm new concepts and expand on new ideas, discover the most engaging headline variations, and scale up your copywriting. </p><p>Using the headline tool is as simple as clicking "Swap Headline" until the AI presents text that will get visitors to your landing page and convert them to customers. </p><p>This updated Leadpages feature is available to new and existing customers. AI Engine will enable users to create better landing pages and websites faster and easier than ever.</p>`,
    category: 'Copywriting',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/AI-Engine-by-leadpages.jpeg',
    website: 'https://www.leadpages.com/product/ai-engine?affiliate=partnerstack&utm_source=affiliate-bz&pscd=try.leadpages.com&ps_partner_key=MTE5NjIwOQ&ps_xid=fZmU9i7lkcpgCu&gsxid=fZmU9i7lkcpgCu&gspk=MTE5NjIwOQ',
    email: 'contact@leadpages.com',
    metaDescription: 'A new Leadpages feature set called AI Engine gives you access to time-saving AI technology inside the Drag & Drop Builder',
    description: 'A new Leadpages feature set called AI Engine gives you access to time-saving AI technology inside the Drag & Drop Builder',
    slug: 'leadpages-ai-engine'
  },
  {
    name: 'DigitalFirst',
    longDescription: "<p>Digital First AI is a marketing tool that claims to help users create and execute marketing plans in seconds using artificial intelligence. According to the tool's creators, the process involves answering a few questions about your business, after which the AI recommends the most effective marketing tactics and methods of popular brands and experienced marketers from a library of over 500 strategies.</p><p>The tool also includes a Funnel Management feature that allows businesses to manage their sales and marketing funnels, a dashboard to view all marketing activities in one place, and a Tactical Flow feature that allows businesses to assign marketing tasks to their team. There's a Content Generation feature that assists with the generation of content, and a Marketing Planning feature that helps you map out your strategy.</p><p>Alternatives: Bizway</p>",
    category: 'Marketing',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/digital-first-marketing-AI.jpeg',
    website: 'https://www.digitalfirst.ai/',
    email: 'contact@digitalfirst.ai',
    metaDescription: 'Digital First AI is a marketing tool that claims to help users create and execute marketing plans in seconds using artificial intelligence',
    description: 'Digital First AI is a marketing tool that claims to help users create and execute marketing plans in seconds using artificial intelligence',
    slug: 'digitalfirst'
  },
  {
    name: 'Zyro',
    longDescription: '<p></p>',
    category: 'Marketing',
    imgUrl: 'https://storage.googleapis.com/hexomatic-screenshot/60ead9f07c491200103fb892/633846f27dd6e800101a60ca/crop-image/1669980459235-https---zyro.com--LaptopSmall-1669980421457.jpeg',
    website: 'https://zyro.com/?ref=spiff.store',
    email: 'contact@zyro.com',
    metaDescription: '',
    description: '',
    slug: 'zyro'
  },
  {
    name: 'Photosonic',
    longDescription: '<p></p>',
    category: 'Generative Art',
    imgUrl: 'https://storage.googleapis.com/hexomatic-screenshot/60ead9f07c491200103fb892/633846f27dd6e800101a60ca/crop-image/1670222051339-https---photosonic.writesonic.com--LaptopSmall-1670221273664.jpeg',
    website: 'https://writesonic.com/photosonic-ai-art-generator',
    email: 'contact@writesonic.com',
    metaDescription: '',
    description: '',
    slug: 'photosonic'
  },
  {
    name: 'Artflow',
    longDescription: '<p>Artflow.ai is an AI-powered app that allows users to effortlessly turn their creative ideas into animated stories. Users can create their own original characters, scenes, and voices, and write their own dialogue to see their stories come to life. </p><p>The app uses AI and machine learning algorithms to generate portraits based on short, textual input from the user, making it easy to create visuals with just a creative idea. Artflow.ai is free to use and requires no downloads or account creations, making it highly accessible for anyone looking to create unique and interesting art. </p><p>Use this free app to easily create visual content and bring their creative ideas to life with the help of AI technology.</p><p>Alternatives: Endless VN</p>',
    category: 'Generative Art',
    imgUrl: 'https://storage.googleapis.com/hexomatic-screenshot/60ead9f07c491200103fb892/633846f27dd6e800101a60ca/crop-image/1670052664777-https---artflow.ai--LaptopSmall-1670052597168.jpeg',
    website: 'https://artflow.ai/?ref=spiff.store',
    email: 'contact@artflow.ai',
    metaDescription: 'Artflow',
    description: 'Artflow',
    slug: 'artflow'
  },
  {
    name: 'Character AI',
    longDescription: '<p></p>',
    category: 'Chat',
    imgUrl: 'https://p190.p3.n0.cdn.getcloudapp.com/items/eDunLnrk/aec80cb7-732a-4429-9814-2f28c75a0022.png?source=viewer&v=36ce0ad4f1bb504c66af0f8bd651537f',
    website: 'https://beta.character.ai/?ref=spiff.store',
    email: 'contact@character.ai',
    metaDescription: '',
    description: '',
    slug: 'character-ai'
  },
  {
    name: 'Durable',
    longDescription: '<p></p>',
    category: 'Marketing',
    imgUrl: 'https://p190.p3.n0.cdn.getcloudapp.com/items/d5uybKY0/78551786-ab9f-4025-9e7b-0ff000c6ac3f.png?source=viewer&v=f429fec402d151bceefbe78b77b44a60',
    website: 'https://durable.co/ai-website-builder/?ref=spiff.store',
    email: 'contact@durable.co',
    metaDescription: '',
    description: '',
    slug: 'durable'
  },
  {
    name: 'Poised',
    longDescription: '<p></p>',
    category: 'Productivity',
    imgUrl: 'https://storage.googleapis.com/hexomatic-screenshot/60ead9f07c491200103fb892/633846f27dd6e800101a60ca/crop-image/1670222051830-https---www.poised.com--LaptopSmall-1670221288874.jpeg',
    website: 'https://www.poised.com/?ref=spiff.store',
    email: 'contact@poised.com',
    metaDescription: '',
    description: '',
    slug: 'poised'
  },
  {
    name: 'Soundraw',
    longDescription: '<p></p>',
    category: 'Music',
    imgUrl: 'https://storage.googleapis.com/hexomatic-screenshot/60ead9f07c491200103fb892/633846f27dd6e800101a60ca/crop-image/1670222053270-https---soundraw.io--LaptopSmall-1670221402557.jpeg',
    website: 'https://soundraw.io/?ref=spiff.store',
    email: 'contact@soundraw.io',
    metaDescription: '',
    description: '',
    slug: 'soundraw'
  },
  {
    name: 'Cleanvoice',
    longDescription: '<p></p>',
    category: 'Podcasting',
    imgUrl: 'https://storage.googleapis.com/hexomatic-screenshot/60ead9f07c491200103fb892/633846f27dd6e800101a60ca/crop-image/1670222087191-https---cleanvoice.ai--LaptopSmall-1670221693646.jpeg',
    website: 'https://cleanvoice.ai/?ref=spiff.store',
    email: 'contact@cleanvoice.ai',
    metaDescription: '',
    description: '',
    slug: 'cleanvoice'
  },
  {
    name: 'Glasp',
    longDescription: '<p></p>',
    category: 'Social Media',
    imgUrl: 'https://storage.googleapis.com/hexomatic-screenshot/60ead9f07c491200103fb892/633846f27dd6e800101a60ca/crop-image/1670222088629-https---glasp.co-ai-writing-LaptopSmall-1670221862371.jpeg',
    website: 'https://glasp.co/ai-writing?ref=spiff.store',
    email: 'contact@glasp.co',
    metaDescription: '',
    description: '',
    slug: 'glasp'
  },
  {
    name: 'Letterdrop',
    longDescription: '<p></p>',
    category: 'Marketing',
    imgUrl: 'https://storage.googleapis.com/hexomatic-screenshot/60ead9f07c491200103fb892/633846f27dd6e800101a60ca/crop-image/1670222089063-https---letterdrop.com--LaptopSmall-1670221877830.jpeg',
    website: 'https://letterdrop.com/?ref=spiff.store',
    email: 'contact@letterdrop.com',
    metaDescription: '',
    description: '',
    slug: 'letterdrop'
  },
  {
    name: 'AISEO Art',
    longDescription: '<p></p>',
    category: 'Generative Art',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/AISEO-art-image-generator.jpeg',
    website: 'https://art.aiseo.ai/',
    email: 'contact@aiseo.ai',
    metaDescription: '',
    description: '',
    slug: 'aiseo-art'
  },
  {
    name: 'Motiva',
    longDescription: '<p></p>',
    category: 'Copywriting',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Motiva-AI-email-marketing-automation-software.jpeg',
    website: 'https://www.motiva.ai/?ref=spiff.store',
    email: 'contact@motiva.ai',
    metaDescription: '',
    description: '',
    slug: 'motiva'
  },
  {
    name: 'FYLM.ai',
    longDescription: '<p></p>',
    category: 'Image Editing',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/fylm-ai-color-grading-professional-tools.jpeg',
    website: 'https://fylm.ai/?irgwc=1',
    email: 'contact@fylm.ai',
    metaDescription: '',
    description: '',
    slug: 'fylm.ai'
  },
  {
    name: 'Procys',
    longDescription: "<p>This AI-driven platform makes it easy to extract data from documents like invoices, receipts, and even personal identification documents like passports. Automate your document management processes across departments or business projects.</p><p>The three-step process for Procys keeps things straightforward: upload, process, and export. You can upload documents from multiple sources, like cloud storage and emails, in formats like PDFs or images. After upload, Procys processes these documents to extract data using its intelligent algorithms, which allows you to export the extracted data seamlessly into your system.</p><p>The security of data is a top priority at Procys, guaranteeing the safeguarding of valuable information. Their processing environment is designed to be secure and compliant with SOC 2 and GDPR, offering detailed audit trails to track every activity.</p><p>With its strong integration capabilities, the platform easily syncs with leading CRM and ERP tools like Microsoft Dynamics, Pipedrive, Zoho, and Salesforce Commerce Cloud.</p><p>The AI's self-learning engine allows it to refine its performance over time by analyzing and processing an increasing volume of documents. The OCR API makes text recognition and data extraction more efficient than ever.</p><p>Procys provides custom data extraction options, ensuring that businesses can customize the platform to meet their specific business needs</p>",
    category: 'Automation',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Procys-AI-document-processing-tool.jpg',
    website: 'https://procys.com/?ref=spiff.store',
    email: 'contact@procys.com',
    metaDescription: 'This AI-driven platform makes it easy to extract data from documents like invoices, receipts, and even personal identification documents like passports',
    description: 'This AI-driven platform makes it easy to extract data from documents like invoices, receipts, and even personal identification documents like passports',
    slug: 'procys'
  },
  {
    name: 'ChatGPT Writer',
    longDescription: '<p></p>',
    category: 'Copywriting',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/ChatGPT-writer.jpeg',
    website: 'https://chatgptwriter.ai/?ref=spiff.store',
    email: 'contact@chatgptwriter.ai',
    metaDescription: '',
    description: '',
    slug: 'chatgpt-writer'
  },
  {
    name: 'Email Magic',
    longDescription: '<p></p>',
    category: 'Copywriting',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Emailmagic-AI.jpeg',
    website: 'https://www.emailmagic.ai/?ref=spiff.store',
    email: 'contact@emailmagic.ai',
    metaDescription: '',
    description: '',
    slug: 'email-magic'
  }
]

// export default TOOLS;
module.exports = TOOLS;
