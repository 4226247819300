import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="brand"><Link to='/'>Spiff.store</Link></div>
      <div className="submit-link"><Link to='/submit-tool'>Submit AI Tool</Link></div>
    </nav>
  );
};

export default Navbar;