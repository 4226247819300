import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from './error-page';
// import TOOLS from './data/Tools';
import ToolPage from './ToolPage';
import Privacy from './Privacy';
import SubmitPage from './SubmitPage';
import TOOLS_Page1 from './data/ai-tools/page-1';
import TOOLS_Page2 from './data/ai-tools/page-2';
import TOOLS_Page3 from './data/ai-tools/page-3';
import TOOLS_Page4 from './data/ai-tools/page-4';
import TOOLS_Page5 from './data/ai-tools/page-5';
import TOOLS_Page6 from './data/ai-tools/page-6';
import TOOLS_Page7 from './data/ai-tools/page-7';
import TOOLS_Page8 from './data/ai-tools/page-8';
import TOOLS_Page9 from './data/ai-tools/page-9';
import TOOLS_Page10 from './data/ai-tools/page-10';
import TOOLS_Page11 from './data/ai-tools/page-11';
import TOOLS_Page12 from './data/ai-tools/page-12';
import TOOLS_Page13 from './data/ai-tools/page-13';
import TOOLS_Page14 from './data/ai-tools/page-14';
import TOOLS_Page15 from './data/ai-tools/page-15';
import TOOLS_Page16 from './data/ai-tools/page-16';
import TOOLS_Page17 from './data/ai-tools/page-17';
import TOOLS_Page18 from './data/ai-tools/page-18';
import META from './data/meta';
import BLOGS from './data/blogs/BLOGS';
import BlogPage from './BlogPage';
import BlogsPage from './BlogsPage';


const TOOLS = [...TOOLS_Page1, ...TOOLS_Page2, ...TOOLS_Page3, ...TOOLS_Page4, ...TOOLS_Page5, ...TOOLS_Page6, ...TOOLS_Page7, ...TOOLS_Page8, ...TOOLS_Page9, ...TOOLS_Page10, ...TOOLS_Page11, ...TOOLS_Page12, ...TOOLS_Page13, ...TOOLS_Page14, ...TOOLS_Page15, ...TOOLS_Page16, ...TOOLS_Page17, ...TOOLS_Page18]


// Assuming TOOLS is an array of tool objects
const categoriesCount = {};

// Counting categories
TOOLS.forEach(tool => {
  if (categoriesCount[tool.category]) {
    categoriesCount[tool.category]++;
  } else {
    categoriesCount[tool.category] = 1;
  }
});

// Converting categoriesCount object to array
const categoriesArray = Object.entries(categoriesCount)
  .map(([category, count]) => ({ category, count }))
  .filter(({ count }) => count > 5);

console.log(categoriesArray);

// console.log(TOOLS)

const routes = TOOLS.map(tool => {
    return {
      "path": "/tool/" + tool?.slug,
      "element": <ToolPage tool={tool} />,
      "errorElement": <ErrorPage />
    }}
)

const blogs = BLOGS.map(blog => {
    return {
      "path": "/ai-blog/" + blog?.slug,
      "element": <BlogPage blog={blog} TOOLS={TOOLS.filter(tool => tool.category === blog.category).slice(0,blog.numberOfTools)} />,
      "errorElement": <ErrorPage />
    }}
)

// console.log(routes)

// console.log(META)

const pages = [] 
for (let index = 1; index < 16; index++) {
  pages.push({
    path: "/ai-tools/page-" + (index+1),
    element: <App TOOLS={TOOLS.slice(24*index,24*(index+1))} META={META[index-1]} />,
    errorElement: <ErrorPage />,
  },)
  
}

// console.log(pages)

const HOME_META ={title: "AI Tools Directory - List of Best AI Websites", desc: "Internet's best database for AI tools. Discover top AI websites or submit your own tool for more traffic."}


const router = createBrowserRouter([
  {
    path: "/",
    element: <App TOOLS={TOOLS_Page1} META={HOME_META} />,
    errorElement: <ErrorPage />,
  },
  ...pages,
  ...blogs,
  {
    path: "/ai-blog",
    element: <BlogsPage BLOGS={BLOGS} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/submit-tool",
    element: <SubmitPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
    errorElement: <ErrorPage />,
  },
  ...routes
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
