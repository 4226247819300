import React from 'react';
import './PricingCards.css'; // Import your CSS file for styling
import { FaCheck } from 'react-icons/fa';

const PricingCards = () => {
  return (
    <div className="pricing-cards-container">
      <div className="pricing-card">
        <h2>FREE Listing</h2>
        <p className="price">$0</p>
        <a className="pay-button" href="https://forms.gle/jBxEe4sGegNhrXuA6" target='_blank' rel="noreferrer">Submit AI Tool</a>
        <p className="pay-once-text">No payment required</p>
        <ul className="features">
          <li><FaCheck color='#32de84' /> 2 months waiting time</li>
          <li><FaCheck color='#32de84' /> Dedicated Listing Page</li>
          <li><FaCheck color='#32de84' /> 1 no-follow backlink</li>
        </ul>
        
      </div>
      <div className="pricing-card" id="fastlane">
        <h2>Fastlane Listing</h2>
        <p className="price">$15</p>
        <a className="pay-button" href='https://forms.gle/Sj23mYd45C8TH85P7' target='_blank' rel="noreferrer">Submit AI Tool</a>
        <p className="pay-once-text">Pay Once</p>
        <ul className="features">
          <li><FaCheck color='#32de84' /> 2 days waiting time</li>
          <li><FaCheck color='#32de84' /> Dedicated Listing Page</li>
          <li><FaCheck color='#32de84' /> Home page Feature</li>
          <li><FaCheck color='#32de84' /> 100+ do-follow backlinks</li>
          <li><FaCheck color='#32de84' /> Listed on 10+ sister sites</li>
          <li><FaCheck color='#32de84' /> Blog post mention</li>
          <li><FaCheck color='#32de84' /> Shared on socials</li>
          <li><FaCheck color='#32de84' /> Increased Domain Authority</li>
          <li><FaCheck color='#32de84' /> Boost in SEO Traffic</li>
        </ul>
        
      </div>
    </div>
  );
};

export default PricingCards;
