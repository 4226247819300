import React, { useState } from 'react';
import styles from './SearchComponent.module.css'
import TOOLS_Page1 from '../data/ai-tools/page-1';
import TOOLS_Page2 from '../data/ai-tools/page-2';
import TOOLS_Page3 from '../data/ai-tools/page-3';
import TOOLS_Page4 from '../data/ai-tools/page-4';
import TOOLS_Page5 from '../data/ai-tools/page-5';
import TOOLS_Page6 from '../data/ai-tools/page-6';
import TOOLS_Page7 from '../data/ai-tools/page-7';
import TOOLS_Page8 from '../data/ai-tools/page-8';
import TOOLS_Page9 from '../data/ai-tools/page-9';
import TOOLS_Page10 from '../data/ai-tools/page-10';
import TOOLS_Page11 from '../data/ai-tools/page-11';
import TOOLS_Page12 from '../data/ai-tools/page-12';
import TOOLS_Page13 from '../data/ai-tools/page-13';
import TOOLS_Page14 from '../data/ai-tools/page-14';
import TOOLS_Page15 from '../data/ai-tools/page-15';
import TOOLS_Page16 from '../data/ai-tools/page-16';
import TOOLS_Page17 from '../data/ai-tools/page-17';
import TOOLS_Page18 from '../data/ai-tools/page-18';
import { FaSearch } from 'react-icons/fa';

const TOOLS = [...TOOLS_Page1, ...TOOLS_Page2, ...TOOLS_Page3, ...TOOLS_Page4, ...TOOLS_Page5, ...TOOLS_Page6, ...TOOLS_Page7, ...TOOLS_Page8, ...TOOLS_Page9, ...TOOLS_Page10, ...TOOLS_Page11, ...TOOLS_Page12, ...TOOLS_Page13, ...TOOLS_Page14, ...TOOLS_Page15, ...TOOLS_Page16, ...TOOLS_Page17, ...TOOLS_Page18]

const SearchComponent = ({onSubmit, setSearchTerm}) => {
  const [query, setQuery] = useState('');
  // const [searchResults, setSearchResults] = useState([]);
  // const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const handleChange = event => {
    const term = event.target.value;
    setQuery(term)
    filterResults(term);
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation()
    // setSuggestions([])
    setSearchTerm(query);
    onSubmit(suggestions);
    // console.log(suggestions)
  };

const filterResults = term => {
  setSuggestions([])
  const filteredResultsSet = new Set(); // Using Set to avoid duplicates
  const exactMatchResults = [];
  const partialMatchResults = [];
  const lowerCaseTerm = term.toLowerCase();

  TOOLS.forEach(item => {
    const lowerCaseName = item.name.toLowerCase();
    if (lowerCaseName === lowerCaseTerm) {
      exactMatchResults.push(item);
    } else if (
      lowerCaseName.includes(lowerCaseTerm) ||
      item.description.toLowerCase().includes(lowerCaseTerm) ||
      item.metaDescription.toLowerCase().includes(lowerCaseTerm) ||
      item.category.toLowerCase().includes(lowerCaseTerm) ||
      item.longDescription.toLowerCase().includes(lowerCaseTerm)
    ) {
      partialMatchResults.push(item);
    }
  });

  // Combine exact matches and partial matches, ensuring no duplicates
  exactMatchResults.forEach(item => filteredResultsSet.add(item));
  partialMatchResults.forEach(item => filteredResultsSet.add(item));

  // Convert Set back to an array
  let filteredResults = Array.from(filteredResultsSet);

  // Sort by placing exact matches first, then by previous priority-based matching and sorting
  filteredResults.sort((a, b) => {
    const isExactMatchA = exactMatchResults.includes(a);
    const isExactMatchB = exactMatchResults.includes(b);

    if (isExactMatchA && !isExactMatchB) {
      return -1; // Move a (exact match) before b
    } else if (!isExactMatchA && isExactMatchB) {
      return 1; // Move b (exact match) before a
    } else {
      // Perform previous priority-based sorting for non-exact matches
      const aScore = calculateScore(a, lowerCaseTerm);
      const bScore = calculateScore(b, lowerCaseTerm);
      return bScore - aScore; // Sort in descending order of score
    }
  });

  setSuggestions(filteredResults);
};

const calculateScore = (item, term) => {
  let score = 0;

  // Adjust the score based on the priority of matches
  if (item.name.toLowerCase().includes(term.toLowerCase())) {
    score += 100; // Highest priority for name match
  }
  if (item.description.toLowerCase().includes(term.toLowerCase())) {
    score += 80;
  }
  if (item.metaDescription.toLowerCase().includes(term.toLowerCase())) {
    score += 60;
  }
  if (item.category.toLowerCase().includes(term.toLowerCase())) {
    score += 40;
  }
  if (item.longDescription.toLowerCase().includes(term.toLowerCase())) {
    score += 20;
  }

  return score;
};



  return (
    <div className={styles.searchContainer}>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Search..."
          value={query}
          onChange={handleChange}
          className={styles.searchInput}
          list="suggestions"
        />
        <datalist id="suggestions">
          {suggestions.map(item => (
            <option key={item.slug} value={item.name} />
          ))}
        </datalist>
        <button type="submit" className={styles.submitButton}>
          <FaSearch /> Search
        </button>
      </form>
    </div>
  );
};

export default SearchComponent;
