const TOOLS = [
  {
    name: 'Safuri',
    longDescription: "<p>AI coding assistant that helps to streamline the coding process for developers.</p><p>Safuri is an AI-based IDE extension buddy that's designed to lend a helping hand during coding, debugging, and refactoring. Imagine having a virtual sidekick giving you solutions and suggestions every step of the way, making your development process smoother and more efficient.</p><p>Train the AI on your project to get incredibly precise responses. Use natural-language search features for your projects and benefit from shortcuts for requesting explanations, optimizations, or unit tests.</p><p>Safurai is currently in Beta and currently free for everyone. It supports all major coding languages and will soon be compatible with a variety of popular IDEs. Supports multiple programming languages, including Python, JavaScript, C#, C++, Java, and Go.</p>",
    category: 'Coding',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Safuri-AI-coding-assistant.jpeg',
    website: 'https://www.safurai.com/?ref=spiff.store',
    email: 'contact@safurai.com',
    metaDescription: 'AI coding assistant that helps to streamline the coding process for developers',
    description: 'AI coding assistant that helps to streamline the coding process for developers',
    slug: 'safuri'
  },
  {
    name: 'RankPress',
    longDescription: '<p>RankPress, a ChatGPT/OpenAI and Amazon Wordpress Autoblogging SaaS Platform.</p><p>This autoblogging tool fuses the power of OpenAI with Amazon, WordPress, and WooCommerce. RankPress offers you customizable, auto-translated and paraphrased Amazon product descriptions, integration with Youtube videos, and AI-generated blog elements, with almost endless possibilities for creating truly unique content.</p><p>Create autoblogs in over 110 languages and integrate with existing platforms and template managers, ensuring each blog has a distinct twist.</p><p>Future updates include additional API integrations, enhanced image processing, and access to custom AI models for content creation. Experience cutting-edge autoblogging on steroids.</p><p>Import Amazon products and create a unique product page for each item, including incorporating Youtube videos and PAA questions and answers. Additionally, users have access to a WordPress Autoblogging tool that generates unique content based on Google snippets and PAA questions and answers. </p><p>Get access to lifetime updates and early access pricing.</p>',
    category: 'Marketing',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/RankPress-AI-Autoblogging-platform.jpg',
    website: 'https://rankpress.io/?ref=spiff.store',
    email: 'contact@rankpress.io',
    metaDescription: 'RankPress, a ChatGPT/OpenAI and Amazon Wordpress Autoblogging SaaS Platform',
    description: 'RankPress, a ChatGPT/OpenAI and Amazon Wordpress Autoblogging SaaS Platform',
    slug: 'rankpress'
  },
  {
    name: 'Monkeebrain',
    longDescription: '<p>Create content efficiently with MonkeebrainAI. Input some descriptions and specify your content requirements. Let the smart algorithm generate blog articles, product descriptions, headlines, emails, newsletters, and whatever else you need. </p><p>The development of custom prompt templates reflects the emphasis on tailoring AI and ChatGPT to address everyday needs. This goes beyond text generation to include use cases like generating structured data. Use Monkeebrain to get ideas, write text, headlines, perform proofreading, and generate descriptions, meta-data, structured data, and more. </p><p>Monkeebrain also aids in understanding RegEx and third-party code snippets.</p>',
    category: 'Copywriting',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/monkeebrain-ai-content-generator.jpeg',
    website: 'https://www.monkeebrain.com?ref=spiff.store',
    email: 'contact@monkeebrain.com',
    metaDescription: 'Create content efficiently with MonkeebrainAI',
    description: 'Create content efficiently with MonkeebrainAI',
    slug: 'monkeebrain'
  },
  {
    name: 'Audo',
    longDescription: "<p>Audo Studio is an audio cleaning tool that removes background noise, reduces echoes, and adjusts volume levels. It saves you time and effort while enhancing the quality of your podcast, YouTube, or other audio files. </p><p>With a live demo for testing, it's already pleasing 25,000+ users, working 10x faster than Adobe and Audacity. Various pricing plans are available, from limited free usage to more extensive coverage. Get ready for improved audio in seconds.</p>",
    category: 'Audio',
    imgUrl: 'https://assets.aitoolsdirectory.com/file/aitools/Audo-studio-audio-for-podcasts-and-YouTube.jpeg',
    website: 'https://audo.ai/?ref=spiff.store',
    email: 'contact@audo.ai',
    metaDescription: 'Audo Studio is an audio cleaning tool that removes background noise, reduces echoes, and adjusts volume levels',
    description: 'Audo Studio is an audio cleaning tool that removes background noise, reduces echoes, and adjusts volume levels',
    slug: 'audo'
  }
]

// export default TOOLS;
module.exports = TOOLS;
