import React from 'react';
import styles from './BlogPage.module.css';
import { Link } from 'react-router-dom';
import Footer from './componenets/Footer';
import Navbar from './componenets/Navbar';
import { Helmet } from 'react-helmet';
import LazyLoad from 'react-lazyload';

const BlogPage = ({ blog, TOOLS }) => {
  console.log(TOOLS)
  const currentDate = new Date();

  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  const formattedDate = currentDate.toLocaleDateString('en-US', options);

    const jsonLd = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `https://spiff.store/ai-blog/${blog?.slug}`
    },
    "headline": blog?.title,
    "image": blog?.imgUrl,
    "datePublished": blog?.publishDate || currentDate.toISOString(),
    "dateModified": currentDate.toISOString(),
    "author": {
      "@type": "Organization",
      "name": "Spiff.store"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Spiff.store",
      "logo": {
        "@type": "ImageObject",
        "url": "https://spiff.store/apple-touch-icon.png"
      }
    },
    "description": blog.metaDesc,
    "articleBody": `${blog?.intro} ${TOOLS.map(tool => 
      `${tool?.name}: ${tool?.longDescription}`).join(' ')} ${blog?.conclusion}`
  };

  return (
    <>
    <Helmet>
      <title>{blog.title}</title>
    
    <meta property="og:title" content={`${blog.title}`} />
    <meta property="og:description"
      content={blog.metaDesc} />
    <meta property="og:image" content={blog.imgUrl} />
    <meta property="og:type" content="website" />
    
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={`${blog.title}`} />
    <meta name="twitter:description"
      content={blog.metaDesc} />
    <meta name="twitter:image" content={blog.imgUrl} />
    
    <meta name="description" content={blog.metaDesc} />

    <script type="application/ld+json">
      {JSON.stringify(jsonLd)}
    </script>
    </Helmet>
    <Navbar />
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>{blog.title}</h1>
        <p className={styles.category}>Last Updated At: {formattedDate}</p>
        <LazyLoad height={200} offset={100}>
          <img className={styles.img} src={blog.imgUrl} alt={blog.title} />
        </LazyLoad>
      </div>
      <div className={styles.content}>
        <p className={styles.intro}>{blog.intro}</p>
        {TOOLS.map((tool, idx) => (
          <div key={tool.slug} className={styles.tool}>
            <h2 className={styles.toolName}>{idx+1}. {tool.name}</h2>
            <LazyLoad height={200} offset={100}>
              <img className={styles.toolImg} src={tool.imgUrl} alt={tool.name} />
            </LazyLoad>
            <div className={styles.toolDescription} dangerouslySetInnerHTML={{__html: tool?.longDescription}} ></div>
            <Link to={`/tool/${tool.slug}`} className={styles.toolLink}>Learn more</Link>
          </div>
        ))}
        <p className={styles.conclusion}>{blog.conclusion}</p>

        <hr/>
        <h2>Submit AI Tool</h2>
        <p>Are you a developer with an innovative AI tool waiting to be discovered? We'd love to hear from you! Share your creation with our community and unlock new opportunities for visibility, traffic, and growth. Whether it's a groundbreaking generative AI tool or a clever productivity app, your contribution could revolutionize how we work and create. <Link to='/submit-tool'>Submit your AI tool</Link> today and join us in shaping the future of technology. Together, let's unleash the power of AI and propel our collective creativity to new heights!</p>
      </div>

    </div>
    <Footer />
    </>
  );
};

export default BlogPage;
